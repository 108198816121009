import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface GraphData {
  graph_details: { month: string; [key: string]: any }[];
}
import { removeStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  midOfMonthData:any,
  nextSixMonthsAns2:any,
  interval:any;
  activeGoalStep:number;
  activeSUSStep:number;
  month: string;
  qustionStart:boolean,
  dataAns:any,
  goalsResponse:any,
  saferResponse:any,
  userName:any,
  goalsTotalTask: any,
  saferUserStrategyTotal: any
  popupShow:any,
  isAssessemtnFilled: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardControllerInformation extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  GoalsTotalTaskApiCallId: string = "";
  saferUserTotalApiCallId: string = "";
  getGoalsData:string="";
  getUser:string="";
  getPopupShowApiCallId: string = "";
  scrollRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
     /* istanbul ignore next */
    this.state = {
      isLoading: false,
      nextSixMonthsAns2:[],
      midOfMonthData:[],
      interval: (() => {
        if (window.innerWidth < 400) {
          return 1;
        } else {
          return 0;
        }
      })(),
      activeGoalStep:0,
      activeSUSStep: 0,
      month:"",
      qustionStart:false,
      dataAns:[],
      userName:'',
      goalsTotalTask: {achieved: 0, total: 0},
      saferUserStrategyTotal: {achieved: 0, total: 0},
      goalsResponse:{},
      saferResponse:{},
      popupShow:false,
      isAssessemtnFilled: false,
    };

  
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.updateInterval = this.updateInterval.bind(this);
    setTimeout(() => {
    this.scrollRef.current &&
      this.scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
   this.NewUserIdentify();
    if (typeof window.addEventListener === "function") {
    window.addEventListener("resize", this.updateInterval);  
    }
    removeStorageData("selectedCardId");
    removeStorageData("susSelectedCategory");
    removeStorageData("susWeekValue");
    removeStorageData("selectedCategory");
    removeStorageData("weekValue");
    removeStorageData("goalValue");
    removeStorageData("newData");
    removeStorageData("selectedSubstance");
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    removeStorageData("activeTab");
    localStorage.removeItem("valuesSequence");
    localStorage.removeItem("currentValue");
    localStorage.removeItem("selectedTab");
    removeStorageData("selectedSubstance");
    this.handleGoalsDashboard();
    this.setState({
      userName: localStorage.getItem("username")
    })

    this.getGoalsCount()
    this.getSaferUserCount()
    this.PopupShowGetApiCall()
    removeStorageData("BackToSaferuseStrategies")
    setTimeout(() => { 
     this.handleGraph();
     this.handleMidPointValue();
    }, 1000);
   
  }

  // Customizable Area Start
  handleMidPointValue() {
    const monthIndices: { [key: string]: number } = {
      Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
      Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11,
    };
    const graphDataAns = localStorage.getItem("GraphData");
    let accountCreatedAns = {} as any;
    try {
      accountCreatedAns = graphDataAns ? JSON.parse(graphDataAns) : {};
    } catch (e) {
    
    }
  
    const createdDateAns = this.convertToMonthYear(accountCreatedAns.account_created_at) || accountCreatedAns?.account_created;
    /* istanbul ignore next */
    if (!createdDateAns) {
      return;
    }
  
    const [monthAns, baseYearStr] = createdDateAns?.split(" ");
    const baseYear = Number(baseYearStr);
    if (!monthAns || isNaN(baseYear)) {
      return;
    }
  
    const monthArrayAns = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const startIndexAns = monthArrayAns?.findIndex(mIndex => mIndex?.startsWith(monthAns?.slice(0, 3)));
       /* istanbul ignore next */
    if (startIndexAns === -1) {
     
      return;
    }
  
    const nextSixMonthsAns = Array.from({ length: 6 }, (_, i) => {
      const monthIndexAns = (startIndexAns + i) % 12;
      return monthArrayAns[monthIndexAns];
    });
  
    const nextSixMonthsAns2 = nextSixMonthsAns?.map(month => {
      const monthIndex = monthIndices[month];
      const year = monthIndex >= startIndexAns ? baseYear : baseYear + 1;
      return new Date(year, monthIndex, 1).getTime();
    });
  
    this.setState({ nextSixMonthsAns2 });
  
    const midOfMonthData = nextSixMonthsAns2?.map((monthTimestamp, index) => {
      if (index === nextSixMonthsAns2?.length - 1) return null;
  
      const nextMonthTimestamp = nextSixMonthsAns2[index + 1];
      if (!monthTimestamp || !nextMonthTimestamp) return null;
  
      const midPointTimestamp = (monthTimestamp + nextMonthTimestamp) / 2;
      return { 
        date: midPointTimestamp,
        harm: 0,
      };
    }).filter(item => item !== null);
    this.setState({ midOfMonthData })
  }
  formatTickToShortMonth = (tick: number) => {
    const { interval } = this.state;
    const monthIndex = new Date(tick).getMonth();
    if (interval === 1 && monthIndex % 2 == 0) {
      return '';
    }
    return new Date(tick).toLocaleDateString('en-US', { month: 'short' });
  };
 handleGraph(){
  const graphData = localStorage.getItem("GraphData");
  let accountCreated = [];
  try {
    accountCreated = graphData ? JSON.parse(graphData) : [];
  } catch (e) {
  } 
  const createdDate = accountCreated?.account_created;
  const month = createdDate &&  createdDate?.split(" ")[0];
  const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const startIndex = month ? monthArray.findIndex(m => m.startsWith(month?.slice(0, 3))) : -1;
  const nextSixMonths = Array.from({ length: 6 }, (_, i) => {
    const monthIndex = (startIndex + i) % 12;
    return monthArray[monthIndex];
  });
  
  const storedData = localStorage.getItem("GraphData");
  let answer: GraphData = { graph_details: [] };

  try {
    answer = storedData ? JSON.parse(storedData) : { graph_details: [] };
  } catch (error) {
    answer = { graph_details: [] };
  }

const updatedData = configJSON.data?.map((monthItem: any) => {
  const matchingEntries = answer?.graph_details?.filter((entry: { month: any }) => entry.month === monthItem.month);
  
  if (matchingEntries?.length > 0) {
      return matchingEntries?.map((entry:any) => ({
        month: monthItem.month,
        date: entry.date,
        harm: entry.score,
      }));
    } else {
      return [{
        month: monthItem.month,
        date: null,
      }];
    }
  }).flat();
  const filteredData = updatedData
  ?.filter((item: { month: string, date: string | null }) => nextSixMonths?.includes(item.month) && item.date !== null)
  .map(({ month, ...rest }:any) => rest); 
  filteredData.sort((a:any,b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())
  this.setState({ dataAns: filteredData });
 }
  updateInterval = () => {
    let answer;
  
    if (window.innerWidth < 400) {
      answer = 1;
    } else {
      answer = 0;
    }
  
    this.setState({ interval:answer });
  }


 
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

        if (apiRequestCallId === this.GoalsTotalTaskApiCallId) {
          this.setState({goalsTotalTask: {achieved: responseJson.achieved_count, total: responseJson.total_count}})
        }
        if (apiRequestCallId === this.saferUserTotalApiCallId) {
          this.setState({ isLoading: false, saferResponse:responseJson }, () => {
            this.setState({
              activeSUSStep: this.state.saferResponse.safer_user_strategies.map((goal: any) => goal.status).lastIndexOf("achieved")
            });
          });
          this.setState({saferUserStrategyTotal: {achieved: responseJson.achieved_strategies, total: responseJson.total_strategies}})
        }
        if(apiRequestCallId===this.getGoalsData){
          this.setState({ isLoading: false, goalsResponse:responseJson }, () => {
            this.setState({
              activeGoalStep: this.state.goalsResponse.goals.map((goal: any) => goal.status).lastIndexOf("achieved")
            });
          });
        }
        if (apiRequestCallId === this.getPopupShowApiCallId){
          this.setState({
            popupShow:responseJson?.harm_reduction_question_available,
            isAssessemtnFilled: responseJson?.filled_earlier,
          });
        }
        if(apiRequestCallId===this.getUser){
          localStorage.setItem("dashboardUser",responseJson.new_user)
        }
    }
    // Customizable Area End
  }
// Customizable Area Start
handleGoalsDashboard(){
  this.setState({ isLoading: true });
  let data = localStorage.getItem("token") || "{}";
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: data
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getGoalsData = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.goalsEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
marginFix(){
  return window.innerWidth <= 500 ? '17px' : '48px'
}
  getGoalsCount = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GoalsTotalTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.goalsTotalEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSaferUserCount = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saferUserTotalApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saferUserTotalEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hamburgerMenux(){
    localStorage.setItem("pageNavigate","DashboardInformation")
    this.props.navigation.navigate("HamburgerMenu")
  }
  goTogoalsScreen = () => {
    removeStorageData("weekValue");
    removeStorageData("goalValue");
    this.props.navigation.navigate("GoalsInformation")
  }
  NewUserIdentify = () => {
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUser = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryShowPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleChangemonth = (event:any) => {
    this.setState({ month: event.target?.value });
  };

  goToSaferseStrategies = () => {
    this.props.navigation.navigate("SaferuseStrategies")
  }
  handleClosestartqustionary = () => {
    this.setState({ qustionStart: false });
  };
  handleOpenqustionary = () => {
    this.setState({ qustionStart: true });
  };

  PopupShowGetApiCall() {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPopupShowApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PopupGetApiendPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PopupGetApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  goToassessment(){
    this.props.navigation.navigate("Assessment")
  }
  getGoalName(goal:any) {
    return (goal?.category ? `${goal?.category}: ` : '') + goal?.title
  }
   
  convertToMonthYear = (utcDateTime:string) => {
    if(!utcDateTime)return
    const date = new Date(utcDateTime);
    if(isNaN(date.getTime())){
      return 
    }
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Extract the month and year
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
  
    return `${month} ${year}`;
  };

  // Customizable Area End
}
