import { 
    Box,
    IconButton,
    Button 
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from "@mui/material/Typography";
import React from 'react'
const DeleteModal = (props:any) =>{
    const {handleClose,isOpen,webStyles, onDelete,goalText} =props
    return (
        <Dialog onClose={handleClose} open={isOpen}
                PaperProps={{
                    style: { borderRadius: 24,overflow: "hidden",boxShadow:"none", width: "391px" }, // Add border radius here
                }}
            >
                <DialogTitle>
                    <Box
                        sx={{
                          ...webStyles.dialogStyleparent
                        }}
                    >
                        <Box
                            sx={{
                            marginTop:'2rem'
                            }}
                        >
                            
                        </Box>
                        <Typography sx={webStyles.Congratulationsstyle}>
                            Confirm Delete
                        </Typography>
                        <Typography sx={webStyles.countingStyle}> Do you want to delete {goalText}?</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Button
                                data-test-id="goalModal"
                                sx={{
                                    padding: "6px 10px",
                                    width: "281px",
                                    height: "44px",
                                    borderRadius: "8px",
                                    border: "none",
                                    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                                onClick={onDelete}
                            >
                                <Typography sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                    fontFamily:'Chromatica-Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    color:"white",
                                }}>
                                    Delete
                                </Typography>
                            </Button>
                            <Button
                                data-test-id="MoveToDashboard"
                                sx={{
                                    width: "281px",
                                    height: "44px",
                                    padding: "6px 10px",
                                    borderRadius: "8px",
                                    border: "none",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                                onClick={handleClose}
                            >
                                <Typography sx={{
                                    fontFamily:'Chromatica-Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    color:"#3BA9D5",
                                    textAlign: "center",
                                }}>
                                    Cancel
                                </Typography>
                            </Button>
                        </Box>
                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Dialog>
    )
}

export default DeleteModal;