// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Tabs,
  Tab,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  CardActions,
  Card,
  Backdrop,
  CircularProgress
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { styled } from "@mui/styles";
import { arrow, menuIcon, textLabel, alcohol, cannabis, tobacco, uppers, downers, others ,substanceBottom,info, button_add, button_minus, loginArrow, calendar, image3,} from "./assets";
import CustomFormController, {
  Props,
  Dropdown,
} from "./CustomFormController.web";
import AlcohalSubstance from "../../goalmanagement/src/AlcohalSubstance.web";
import OtherSubstance from "../../goalmanagement/src/OtherSubstance.web";
import Cannabis from "../../goalmanagement/src/Cannabis.web";
import DatePickerAlcohol from '../../landingpage/src/DatePickerAlcohol.web';

const configJSON = require("./config.js");
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  tobacoMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "153px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.tobacoMeasureType === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.tobacoMeasureType === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="tobacoMeasureType"
        name="tobacoMeasureType"
        value={this.state.tobacoMeasureType}
        onChange={(e) => this.setState({ tobacoMeasureType: e.target.value })}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        data-test-id="tobacoMeasureType"
      >
        <Box sx={getStyle("Cigarettes")}>
          <FormControlLabel
            value="Cigarettes"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Cigarettes"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetTobaccoOthersTextValue}
            data-test-id="resetTobaccoOthersTextValue"
          />
        </Box>
        <Box sx={getStyle("Others")}>
          <FormControlLabel
            value="Others"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Others"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  tobaccoReduceState = () => {
    const getStyle = (value: string) => ({
      width: "227px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.tobacoReduceState === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.tobacoReduceState === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="tobacoReduceState"
        name="tobacoReduceState"
        value={this.state.tobacoReduceState}
        onChange={(e) => this.setState({ tobacoReduceState: e.target.value })}
        sx={{
          mt: "25px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
          zIndex: "2",
        }}
        data-test-id="tobacoReduceState"
      >
        <Box sx={getStyle("reduceHowMuch")}>
          <FormControlLabel
            value="reduceHowMuch"
            onClick={this.resetTobaccoHowOften}
            data-test-id="resetTobaccoHowOften"  
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Reduce how much"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("reduceHowOften")}>
          <FormControlLabel
            value="reduceHowOften"
            onClick={this.resetTobaccoSafer}
            data-test-id="resetTobaccoSafer" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Reduce how often"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      
        <Box sx={getStyle("stopCompletely")}>
          <FormControlLabel
            value="stopCompletely"
            onClick={this.resetTobaccoOthers}
            data-test-id="resetTobaccoOthers" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Stop completely"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("Others")}>
          <FormControlLabel
            value="Others"
            onClick={this.resetTobaccoHowMuch}
            data-test-id="resetTobaccoHowMuch" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Other"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  cigarettesReduceType = () => {
    const getStyle = (value: string) => ({
      width: value === "numberOfCigarettes" ? "240px" : "153px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.cigarettesReduceType === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.cigarettesReduceType === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="cigarettesReduceType"
        name="cigarettesReduceType"
        value={this.state.cigarettesReduceType}
        onChange={this.handleCigarettesReduceTypeChange}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        <Box sx={getStyle("numberOfCigarettes")}>
          <FormControlLabel
            value="numberOfCigarettes"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Number of Cigarettes"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetNumberOfCigarettesValue}
            data-test-id="resetNumberOfCigarettesValue"
          />
        </Box>
        <Box sx={getStyle("Others")}>
          <FormControlLabel
            value="Others"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Others"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetTobaccoreduceHowMuchTextValue}
            data-test-id="resetTobaccoreduceHowMuchTextValue"
          />
        </Box>
      </RadioGroup>
    );
  };

  tobaccoReducsHowMuch = () => {
    const isValidTobaccoreduceHowMuchTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoreduceHowMuchTextValue);
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            p: "8px 16px",
            zIndex: "2",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            mt: "15px",
          }}
        >
          <Box
            component="img"
            src={info.default}
            alt="info"
            sx={{ width: "24px", height: "24px" }}
          />
          <Typography
            sx={{
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            When you think about reducing, how would you want to measure that?
            ex., number of cigarettes, other [please type in]? [choose one]
          </Typography>
        </Box>

        {this.cigarettesReduceType()}
        {this.state.cigarettesReduceType === "numberOfCigarettes" ? (
          <>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              Over the last week, how many Cigarettes did you have on a typical
              day?
            </Typography>
            <Box
              sx={{ display: "flex", width: "130px",zIndex:'2', height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                sx={{
                  mr: "8px", width:'32px', height:'32px', cursor:"pointer"
                }}
                onClick={() =>
                  this.handleCountChange("lastWeekCigarettesCount", -1)
                }
                data-test-id="lastWeekCigarettesCountMinus"
              />
              <input
                                type="text"
                                data-test-id="lastWeekCigarettesCountText"
                                name="lastWeekCigarettesCount"
                                onBlur={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                value={this.state.lastWeekCigarettesCount}
                                onChange={(event)=>this.handleInputCountChange(event)}
                                style={{ 
                                    height: '32px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    color: '#252525',
                                    width: '51px',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    outline: 'none',

                                }}
                                
                                  onFocus={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                            />
              <Box
                component="img"
                alt="button_add"
                src={button_add.default}
                sx={{ ml:"8px", width:'32px', height:'32px', cursor:"pointer" }}
                data-test-id="lastWeekCigarettesCountAdd"
                onClick={() =>
                  this.handleCountChange("lastWeekCigarettesCount", 1)
                }
              />
            </Box>
            {this.state.lastWeekCigarettesCount == 0 && this.state.TobaccoButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#252525",
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex",  mt: "15px" , width: "130px",zIndex:'2', height: "30px",}}
            >
              <Box
                component="img"
                onClick={() =>
                  this.handleCountChange("nextweekCigarettesReduceCount", -1)
                }
                src={button_minus.default}
            
                sx={{
                  mr: "8px", width:'32px', height:'32px', cursor:"pointer"
                }}
                alt="button_minus"
                data-test-id="nextweekCigarettesReduceCountMinus"
              />
              <input
               style={{ 
                height: '32px',
                fontSize: '16px',
                fontFamily: 'Chromatica-Regular',
                color: '#252525',
                border: '1px solid #E3E3E3',
                width: '51px',
                borderRadius: '4px',
                textAlign: 'center',
                outline: 'none',

            }}
                                data-test-id="nextweekCigarettesReduceCountText"
                                type="text"
                                name="nextweekCigarettesReduceCount"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                value={this.state.nextweekCigarettesReduceCount}
                               
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                                  
                            />
              <Box
              onClick={() =>
                this.handleCountChange("nextweekCigarettesReduceCount", 1)
              }
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
                
                data-test-id="nextweekCigarettesReduceCountAdd"
              />
            </Box>
            {this.state.TobaccoButtonCalled && this.state.lastWeekCigarettesCount < this.state.nextweekCigarettesReduceCount &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Select value less than {this.state.lastWeekCigarettesCount}
              </p>
            }
          </>
        ) : (
          <>
          <TextField
            placeholder="Enter here..."
            multiline
            value={this.state.tobaccoreduceHowMuchTextValue}
            onChange={this.handleInputChange}
            name="tobaccoreduceHowMuchTextValue"
            InputProps={{
              disableUnderline: false,
              sx: {
                height: "58px",
                "&:after": {
                  borderBottom: "1px solid #E3E3E3",
                },
                ".MuiInputBase-input": {
                  pt: "20px",
                },
                "&:before": {
                  borderBottom: "1px solid #E3E3E3",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "1px solid #E3E3E3",
                },
              },
            }}
            inputProps={{
              style: {
                fontSize: "16px",
                fontFamily: "Chromatica-Regular",
              },
              sx: {
                "&::placeholder": {
                  fontSize: "16px",
                  color: "#939393",
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 700,
                },
              },
            }}
            rows={1}
            variant="standard"
            fullWidth
            sx={{
              maxWidth: "286px",
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
              fontWeight: 500,
              lineHeight: "24px",
              mt: "20px",
              color: "#939393",
              minWidth:'50px'
            }}
          />
         { 

  this.state.TobaccoButtonCalled && (
    (this.state.tobaccoreduceHowMuchTextValue.length < 1 || isValidTobaccoreduceHowMuchTextValue) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: 0,
        marginTop: '5px'
      }}>
        { 
          this.state.tobaccoreduceHowMuchTextValue.length < 1 
            ? "Text field is required!" 
            : "Please enter valid text!"
        }
      </p>
    )
  )
}
          </>
)}
      </>
    );
  };

  TobaccoReduceHowOften = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",        
            fontWeight: 400,
            fontSize: "16px",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            mt: "20px",
            zIndex: "2",
          }}
        >
        {`Out of the past 7 days, how many days did you use ${
                    this.state.tobacoMeasureType === 'Others' 
                    ? this.state.tobaccoOtherMeasureTypeTextValue 
                    : this.state.tobacoMeasureType
                }?`}
        </Typography>
        <Box sx={{ display: "flex", height: "30px", mt: "15px" , width: "130px",zIndex:'2',}}>
          <Box       
            alt="button_minus"
            component="img"
            src={button_minus.default}
            sx={{
              height:'32px', mr:"8px", width:'32px', cursor:"pointer"
            }}
            onClick={() =>
              this.handleCountChange("pastSevenDaysCigarettesCount", -1)
            }
            data-test-id="pastSevenDaysCigarettesCountMinus"
          />
          <input
                                data-test-id="pastSevenDaysCigarettesCountText"
                                type="text"
                                name="pastSevenDaysCigarettesCount"
                                onBlur={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }} 
                                onChange={(event)=>this.handleInputHowOftenCountChange(event)}
                                value={this.state.pastSevenDaysCigarettesCount}
                                style={{ 
                                    height: '32px',
                                    fontSize: '16px',
                                    fontFamily: 'Chromatica-Regular',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',

                                }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                
                            />
          <Box
            src={button_add.default}
            component="img"
            onClick={() =>
              this.handleHowOftenCountChange("pastSevenDaysCigarettesCount", 1)
            }
            data-test-id="pastSevenDaysCigarettesCountAdd"
            alt="button_add"
            sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
          />
        </Box>
        {this.state.pastSevenDaysCigarettesCount == 0 && this.state.TobaccoButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
             {`Out of the NEXT 7 days, how many days do you want to use ${
                    this.state.tobacoMeasureType === 'Others' 
                    ? this.state.tobaccoOtherMeasureTypeTextValue 
                    : this.state.tobacoMeasureType
                }?`}
        </Typography>
        <Box sx={{ display: "flex",width: "130px",zIndex:'2', height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr: "8px", width:'32px', height:'32px', cursor:"pointer"
            }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysCigarettesCount", -1)
            }
            data-test-id="nextSevenDaysCigarettesCountminus"
          />
          <input
                                data-test-id="nextSevenDaysCigarettesCountText"
                                type="text"
                                onBlur={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                name="nextSevenDaysCigarettesCount"
                                onChange={(event)=>this.handleInputHowOftenCountChange(event)}
                                value={this.state.nextSevenDaysCigarettesCount}
                                style={{ 
                                    height: '32px',
                                    fontSize: '16px',
                                    fontFamily: 'Chromatica-Regular',
                                    color: '#252525',
                                    border: '1px solid #E3E3E3',
                                    width: '51px',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    outline: 'none',

                                }}
                                
                            />
          <Box
            component="img"
            src={button_add.default}
            alt="button_minus"
            sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
            onClick={() =>
              this.handleHowOftenCountChange("nextSevenDaysCigarettesCount", 1)
            }
            data-test-id="nextSevenDaysCigarettesCountadd"
          />
        </Box>
        {this.state.TobaccoButtonCalled && this.state.pastSevenDaysCigarettesCount < this.state.nextSevenDaysCigarettesCount &&
          <p
            style={{
              color: "#EB3E3E",
              fontFamily: "Chromatica-Regular",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              margin:0,
              marginTop:'5px'
            }}
          >
            Select value less than {this.state.pastSevenDaysCigarettesCount}
          </p>
        }
      </>
    );
  };

  tobaccoStopCompletelyMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "100px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.isTakingBreakCigarettes == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.isTakingBreakCigarettes == value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="isTakingBreakCigarettes"
        name="isTakingBreakCigarettes"
        value={this.state.isTakingBreakCigarettes}
        onChange={this.handleCigarettesReduceTypeChange}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        data-test-id="isTakingBreakCigarettes"
      >
        <Box sx={getStyle("true")}>
          <FormControlLabel
            value={"true"}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Yes"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("false")}>
          <FormControlLabel
            value={"false"}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="No"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  tobaccoBreakDate = () => {
    const getStyle = (value: string) => ({
      p: "8px 12px",
      border: `1px solid ${
        this.state.tobaccoBreakSelectedDate == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.tobaccoBreakSelectedDate == value
          ? "#EFF8FB"
          : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="tobaccoBreakDay"
        name="tobaccoBreakDay"
        value={this.state.tobaccoBreakSelectedDate}
        onChange={this.handleTobaccoBreakDateChange}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
          zIndex: "2",
        }}
        data-test-id="tobaccoBreakDay"
      >
        {this.state.tobaccoBreakDay.map((date, index) => (
          <Box sx={getStyle(date.format("MM/DD/YYYY"))}>
            <FormControlLabel
              key={index}
              value={date.format("MM/DD/YYYY")}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#00A7D1",
                    },
                    color: "#E3E3E3",
                  }}
                />
              }
              label={
                index === 0
                  ? `Today, ${date.format("ddd MMMM D")}`
                  : date.format("ddd, MMMM D")
              }
              componentsProps={{ typography: typographyProps }}
            />
          </Box>
        ))}
        <Box sx={getStyle("OtherDay")}>
          <FormControlLabel
            value="OtherDay"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Other day"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  TobaccoStopCompletely = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
            maxWidth: "890px",
            minWidth: "100px",
          }}
        >
          {`This is a good goal. It's also a really big goal. Some people approach
          this by reducing or using more safely first. Are you sure you want to
          take a break from ${
            this.state.tobacoMeasureType === 'Others' 
            ? this.state.tobaccoOtherMeasureTypeTextValue 
            : this.state.tobacoMeasureType
        } at this point?`}
        </Typography>
        {this.tobaccoStopCompletelyMeasureType()}
        {this.state.isTakingBreakCigarettes == "" && this.state.TobaccoButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Please select Yes or No. This field is required!</p>}
    
    {this.state.isTakingBreakCigarettes==='false'  && (
      <p
                style={{ 
                  margin: "0",fontSize: "16px",  fontFamily: "Chromatica-Regular",fontWeight: 500,color: "#EB3E3E",
                  lineHeight: "24px",
                   marginBottom: "20px",         
                  marginTop: "20px",}}>Please select a new goal or click on "Yes" to continue.</p>)}
{this.state.isTakingBreakCigarettes!=='false' && ( <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          {`Way to commit to taking a break from ${
                    this.state.tobacoMeasureType === 'Others' 
                    ? this.state.tobaccoOtherMeasureTypeTextValue 
                    : this.state.tobacoMeasureType
                }?`}
        </Typography>
        {this.tobaccoBreakDate()}
        { this.state.tobaccoBreakSelectedDate !== "OtherDay" && this.state.tobaccoBreakSelectedDate == "" && this.state.TobaccoButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px', display: this.state.hideDateError ? "none" : ""}}>Date is required!</p>}
        {this.state.tobaccoBreakSelectedDate == "OtherDay" && (
           <div style={{ maxWidth: '286px', borderBottom: '1px solid #E3E3E3', marginTop: '20px', alignItems: 'center', display: 'flex', }}>
           <input
               style={{
                   border: 'none',
                   marginRight: '10px',
                   backgroundColor: 'transparent',
                   outline: 'none',
                   width: '100%',
                   fontFamily: 'Chromatica-Regular',
                   fontSize: '16px',
                   padding: '10px 0',
               }}
               readOnly
               data-test-id="popip"
               onClick={() => this.openPopUp(1)}
               type="text"
               placeholder="Choose Day"
               value={this.state.tobaccoCalendarSelectedDate}
           />
           <img
               alt="Calendar"
               style={{ cursor: 'pointer', width: '24px', height: '24px' }}
               data-test-id="popimg"
               onClick={() => this.openPopUp(1)}
               src={calendar.default}
           />
       </div>
        )}
        <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openCalender && (

                                    <Card style={{
                                        flexDirection: 'column',
                                        borderRadius: '16px',
                                        gap: '24px',padding: '25px 10px 15px 10px', width: '400px', display: 'flex', backgroundColor: '#FFFFFF', alignItems: 'center'
                                    }}>

                                        <DatePickerAlcohol onChange={this.handleDataCalendar} navigation={undefined} id={''} onAccept={undefined}  value={this.state.formattedDateInter} onClose={undefined} />
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button 
                                             onClick={() => this.closePopUp()}
                                             data-test-id="closebtn"
                                            style={{
                                              textTransform: 'none', width: '90px', height: '43px',
                                              border: '1px solid #00A7D1', background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                borderRadius: '8px',
                                            }}  
                                            > Back
                                            </Button>
                                            <Button 
                                            onClick={() => this.confirmCal()}
                                            data-test-id="confirmBtns"
                                            style={{
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px',  background: '#00A7D1', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '40px',
                                            }}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                        {this.state.dateErrorMessage && (
                          <p
                            style={{
                              color: "#EB3E3E",
                              fontFamily: "Chromatica-Regular",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "24px",
                              margin: "8px 0 0",
                              paddingLeft: "16px",
                            }}
                          >
                            {this.state.dateErrorMessage}
                          </p>
                        )}
                                    </Card>
                                )}
                            </Backdrop>
        {this.state.tobaccoBreakSelectedDate == "OtherDay" && this.state.TobaccoButtonCalled &&
          this.state.tobaccoCalendarSelectedDate?.length < 2  && (
            <p
              style={{
                color: "#EB3E3E",
                margin: "0",
                fontFamily: "Chromatica-Regular",
                fontSize: "16px",
                marginTop: "5px",
                fontWeight: 500,
                lineHeight: "24px",
                display: this.state.hideDateError ? "none" : "",
              }}
            >
              Date is required!
            </p>
          )} </>)}
      </>
    );
  };

  TobaccoOthers = () => {
    const isValidTobaccoOthersTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoOthersTextValue);
    return (
      <>
        <Typography
          sx={{
            mt: "30px",
            zIndex: "2",
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          What do you want to see happen with your use?
        </Typography>
        <TextField
          placeholder="Type here..."
          multiline
          value={this.state.tobaccoOthersTextValue}
          onChange={this.handleInputChange}
          name="tobaccoOthersTextValue"
          InputProps={{
            disableUnderline: false,
            sx: {
              "&:after": {
                borderBottom: "1px solid #E3E3E3",
              },
              ".MuiInputBase-input": {
                paddingBottom: "5px",
              },
              "&:before": {
                borderBottom: "1px solid #E3E3E3",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: "1px solid #E3E3E3",
              },
            },
          }}
          inputProps={{
            style: {
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
            },
            sx: {
              "&::placeholder": {
                fontSize: "16px",
                color: "#939393",
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
              },
            },
          }}
          sx={{
            fontWeight: 700,
            lineHeight: "24px",
            mt: "25px",
            color: "#939393",
            maxWidth: "816px",
            fontSize: "16px",
            fontFamily: "Chromatica-Regular",
            minWidth:'50px'
          }}
          rows={5}
          variant="standard"
          fullWidth
        />
        { 

  this.state.TobaccoButtonCalled && (
    (this.state.tobaccoOthersTextValue.length < 1 || isValidTobaccoOthersTextValue) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: 0,
        marginTop: '5px'
      }}>
        { 
          this.state.tobaccoOthersTextValue.length < 1 
            ? "Text field is required!" 
            : "Please enter valid text!"
        }
      </p>
    )
  )
}
      </>
    );
  };

  selectedTobaccoReduceState = () => {
    const { tobacoReduceState } = this.state;

    switch (tobacoReduceState) {
      case "reduceHowMuch":
        return this.tobaccoReducsHowMuch();

      case "reduceHowOften":
        return this.TobaccoReduceHowOften();

      case "Safer/healthier":
        return (
          <Typography
            sx={{
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              mt: "20px",
              zIndex: "2",
            }}
            data-test-id="Safer/healthier"
          >
            Check out our list of safer use strategies{" "}
            <span
            data-test-id="spanValue"
            onClick={()=>this.SaferHandle()}
              style={{
                fontWeight: 500,
                fontFamily: "Chromatica-Bold",
                borderBottom: "1px solid #252525",
                cursor:"pointer",
              }}
            >
              Here
            </span>{" "}
            to choose your goals for the next week.
          </Typography>
        );

      case "stopCompletely":
        return this.TobaccoStopCompletely();

      case "Others":
        return this.TobaccoOthers();

      default:
        return null;
    }
  };

  Tobacco = () => {
    const isValidTobaccoOtherMeasureTypeTextValue = /^[^a-zA-Z]*$/.test(this.state.tobaccoOtherMeasureTypeTextValue);
    return (
      <>
        { (
          <>
            <Box
              sx={{
                backgroundColor: "#F5F5F5",
                p: "8px 16px",
                zIndex: "2",
                position: "relative",
                borderRadius: "16px",
              }}
            >
              <Box
                component="img"
                src={info.default}
                alt="Logo"
                sx={{ position: "absolute", top: 8, left: 15 }}
              />
              <Typography
                sx={{
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  pl: "30px",
                }}
              >
                "Tobacco" refers to cigarettes, cigars, dip or chew, snus, etc.
                Vapes, e-cigarettes and nicotine patches, lozenges, gums all
                contain nicotine. These are typically safer than tobacco
                products and can be helpful in moving away from tobacco products
                that are more dangerous, like smoking. No matter what you want
                to cut back on, we can help. What are you looking to cut back
                on?
              </Typography>
            </Box>
            {this.tobacoMeasureType()}
            {this.state.tobacoMeasureType === "Others" && (
              <>
              <TextField
                value={this.state.tobaccoOtherMeasureTypeTextValue}
                onChange={this.handleInputChange}
                name="tobaccoOtherMeasureTypeTextValue"
                variant="standard"
                rows={1}
                fullWidth
                placeholder="Enter here..."
                multiline
                sx={{
                  lineHeight: "24px",
                  mt: "20px",
                  color: "#939393",
                  fontSize: "16px",
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                  maxWidth: "286px",
                  minWidth:'50px'
                }}
               
                inputProps={{
                  style: {
                    fontSize: "16px",
                    fontFamily: "Chromatica-Regular",
                    fontWeight: 500,
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      color: "#939393",
                      fontFamily: "Chromatica-Regular",
                      fontWeight: 700,
                    },
                  },
                }}

                InputProps={{
                  disableUnderline: false,
                  sx: {
                    height: "58px",
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
              />
             {

  this.state.TobaccoButtonCalled && (
    (this.state.tobaccoOtherMeasureTypeTextValue.length < 1 || isValidTobaccoOtherMeasureTypeTextValue) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: 0,
        marginTop: '5px'
      }}>
        { 
          this.state.tobaccoOtherMeasureTypeTextValue.length < 1 
            ? "Text field is required!" 
            : "Please enter valid text!"
        }
      </p>
    )
  )
}
              </>
            )}
          </>
        )}
        {this.tobaccoReduceState()}
        {this.selectedTobaccoReduceState()}
        {this.state.isTakingBreakCigarettes!=='false' && 
        <Button
          sx={{
            padding: "16px",
            borderRadius: "16px",
            textTransform: "none",
            background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
            fontSize: "18px",
            fontWeight: 700,
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            maxWidth: "348px",
            height: "56px",
            mb: "15px",
            mt: "25px",
            minWidth:'50px'
          }}
          onClick={this.moveToUppers}
          data-test-id="login-add-goals"
          fullWidth
          variant="contained"
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "24px",
              fontFamily: "Chromatica-Regular",
            }}
          >
            Add Goal
          </Typography>
          <Box
            component="img"
            src={loginArrow.default}
            alt="loginArrow"
            sx={{ ml: "2px" }}
          />
        </Button>
  }
      </>
    );
  };


  upperReduceState = () => {
    const uppersGetStyle = (value: string) => ({
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.upperReduceState === value ? "#EFF8FB" : "transparent",
      width: "227px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.upperReduceState === value ? "#00A7D1" : "#E3E3E3"
      }`,
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
      },
    };

    return (
      <RadioGroup
        row
        aria-label="upperReduceState"
        name="upperReduceState"
        value={this.state.upperReduceState}
        onChange={(e) => this.setState({ upperReduceState: e.target.value })}
        sx={{ mt: "15px", display: "felx",
        justifyContent: "flex-start",
        gap: "10px", }}
        data-test-id="upperReduceState"
      >
        <Box sx={uppersGetStyle("UppersReduceHowMuch")}>
          <FormControlLabel
            value="UppersReduceHowMuch"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Reduce how much"
            onClick={this.resetUppersHowOften}
            data-test-id="resetUppersHowOften"     
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>
        <Box sx={uppersGetStyle("UppersReduceHowOften")}>
          <FormControlLabel
            value="UppersReduceHowOften"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Reduce how often"
            onClick={this.resetUppersSafer}
            data-test-id="resetUppersSafer"  
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>

        <Box sx={uppersGetStyle("UppersStopCompletely")}>
          <FormControlLabel
            value="UppersStopCompletely"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Stop completely"
            onClick={this.resetUppersOthers}
            data-test-id="resetUppersOthers" 
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>
        <Box sx={uppersGetStyle("Others")}>
          <FormControlLabel
            value="Others"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Other"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetUppersHowMuch}
            data-test-id="resetUppersHowMuch" 
          />
        </Box>
      </RadioGroup>
    );
  };

  upperMetricValue = () => {
    const getStyle = (value: string) => ({
      width: "124px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.upperMetricValue === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.upperMetricValue === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="upperMetricValue"
        name="upperMetricValue"
        value={this.state.upperMetricValue}
        onChange={(e) => this.setState({ upperMetricValue: e.target.value })}
        sx={{ mt: "10px", display: "felx",
        justifyContent: "flex-start",
        gap: "10px", }}
        data-test-id="upperMetricValue"
      >
        <Box sx={getStyle("ounces")}>
          <FormControlLabel
            value="ounces"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Ounces"
            componentsProps={{ typography: typographyProps }}
            data-test-id="resetUppersDollarAndHits"
            onClick={this.resetUppersDollarAndHits}
          />
        </Box>
        <Box sx={getStyle("dollars")}>
          <FormControlLabel
            value="dollars"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="$$"
            onClick={this.resetUppersOunceAndHits}
            componentsProps={{ typography: typographyProps }}
            data-test-id="resetUppersOunceAndHits"
          />
        </Box>
        <Box sx={getStyle("hits")}>
          <FormControlLabel
            value="hits"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Hits"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetUppersDollarAndOunces}
            data-test-id="resetUppersDollarAndOunces"
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedUpperMetricValue = () => {
    const { upperMetricValue } = this.state;
    const isValidlastWeekUppersDollarsText = /^\d*$/.test(this.state.lastWeekUppersDollarsTextValue);
    const isValidnextweekUppersDollarsText = /^\d*$/.test(this.state.nextweekUppersDollarsReduceTextValue);
    switch (upperMetricValue) {
      case "ounces":
        return (
          <>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              Over the last week, how many Ounces did you have on a typical day
              when you used?
            </Typography>
            <Box
              sx={{ display: "flex", width: "130px", height: "30px", mt: "15px" ,zIndex:'2'}}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                sx={{
                  mr: "8px", width:'32px', height:'32px', cursor:"pointer"
                }}
                onClick={() =>
                  this.handleCountChange("lastWeekUppersOuncesCount", -1)
                }
                data-test-id="lastWeekOuncesCountMinus"
              />
              <input
                                data-test-id="lastWeekUppersOuncesCountText"
                                type="text"
                                name="lastWeekUppersOuncesCount"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                style={{ 
                                    height: '32px',
                                    fontSize: '16px',
                                    fontFamily: 'Chromatica-Regular',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',

                                }}
                                value={this.state.lastWeekUppersOuncesCount}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }} 
                            />
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
                onClick={() => this.handleCountChange("lastWeekUppersOuncesCount", 1)}
                data-test-id="lastWeekOuncesCountAdd"
              />
            </Box>
            {this.state.lastWeekUppersOuncesCount == 0 && this.state.UppersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex", width: "130px", height: "30px", mt: "15px" ,zIndex:'2'}}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                sx={{
                  width:'32px', height:'32px', mr:"8px", cursor:"pointer"
                }}
                onClick={() =>
                  this.handleCountChange("nextweekUppersOuncessReduceCount", -1)
                }
                data-test-id="nextweekOuncessReduceCountMinus"
              />
              <input
                                data-test-id="nextweekUppersOuncessReduceCountText"
                                type="text"
                                name="nextweekUppersOuncessReduceCount"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                style={{ 
                                    height: '32px',
                                    fontSize: '16px',
                                    fontFamily: 'Chromatica-Regular',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',

                                }}
                                onBlur={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }} 
                                value={this.state.nextweekUppersOuncessReduceCount}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                               
                            />
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
                onClick={() =>
                  this.handleCountChange("nextweekUppersOuncessReduceCount", 1)
                }
                data-test-id="nextweekOuncessReduceCountAdd"
              />
            </Box>
            {this.state.UppersButtonCalled && this.state.lastWeekUppersOuncesCount < this.state.nextweekUppersOuncessReduceCount &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Select value less than {this.state.lastWeekUppersOuncesCount}
              </p>
            }
          </>
        );
      case "dollars":
        return (
          <>
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#252525",
                fontSize: "16px",
                zIndex: "2",
                mt: "20px",
              }}
            >
             Over the last week, how much money did you spend on a typical day when you used?
            </Typography>
            <TextField
                placeholder="Type here..."
                multiline
                rows={1}
                value={this.state.lastWeekUppersDollarsTextValue}
                onChange={this.handleInputChange}
                name="lastWeekUppersDollarsTextValue"
                data-test-id="lastWeekUppersDollarsTextValue"
                variant="standard"
                fullWidth
                sx={{
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  mt: "20px",
                  maxWidth: "286px",
                  color: "#939393",
                  minWidth:'50px'
                }}
                InputProps={{
                  disableUnderline: false,
                  sx: {                  
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontWeight: 700,
                      fontFamily: "Chromatica-Regular",
                      color: "#939393",
                      fontSize: "16px",
                    },
                  },
                }}
              />
             { 

  this.state.UppersButtonCalled && (
    (this.state.lastWeekUppersDollarsTextValue?.length < 1 || !isValidlastWeekUppersDollarsText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0', 
        marginTop: '5px'
      }}>
        { 
          this.state.lastWeekUppersDollarsTextValue?.length < 1 
            ? "This field is required!" 
            : "Please enter a valid number"
        }
      </p>
    )
  )
}
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <TextField
                placeholder="Type here..."
                multiline
                sx={{
                  mt: "20px",
                  lineHeight: "24px",
                  color: "#939393",
                  maxWidth: "286px",
                  fontSize: "16px",
                  minWidth:'50px',
                  fontWeight: 500,
                  fontFamily: "Chromatica-Regular",
                }}
                onChange={this.handleInputChange}
                name="nextweekUppersDollarsReduceTextValue"
                value={this.state.nextweekUppersDollarsReduceTextValue}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      fontFamily: "Chromatica-Regular",
                      color: "#939393",
                      fontWeight: 700,
                    },
                  },
                }}
                rows={1}
                fullWidth
                variant="standard"
              />
            { 
  this.state.UppersButtonCalled && (
    (this.state.nextweekUppersDollarsReduceTextValue?.length < 1 || !isValidnextweekUppersDollarsText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0', 
        marginTop: '5px'
      }}>
        { 
          this.state.nextweekUppersDollarsReduceTextValue?.length < 1 
            ? "This field is required!" 
            : "Please enter a valid number"
        }
      </p>
    )
  )
}
            {this.state.UppersButtonCalled && Number(this.state.lastWeekUppersDollarsTextValue) < Number(this.state.nextweekUppersDollarsReduceTextValue) &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Amount should be less than {this.state.lastWeekUppersDollarsTextValue}
              </p>
            }
          </>
        );
      case "hits":
        return (
          <>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
              }}
            >
              Over the last week, how many Hits did you have on a typical day
              when you used?
            </Typography>
            <Box
              sx={{ display: "flex", width: "130px",zIndex:'2', height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                onClick={() =>
                  this.handleCountChange("lastWeekUppersHitsCount", -1)
                }
                data-test-id="lastWeekUppersHitsCountMinus"
                sx={{
                  mr: "8px", width:'32px', height:'32px', cursor:"pointer"
                }}
              />
               <input
                                data-test-id="lastWeekUppersHitsCountText"
                                type="text"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                name="lastWeekUppersHitsCount"
                                style={{ 
                                    height: '32px',
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',
                                    fontFamily: 'Chromatica-Regular',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',

                                }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                value={this.state.lastWeekUppersHitsCount}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }} 
                            />
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
                onClick={() => this.handleCountChange("lastWeekUppersHitsCount", 1)}
                data-test-id="lastWeekUppersHitsCountAdd"
              />
            </Box>
            {this.state.lastWeekUppersHitsCount == 0 && this.state.UppersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex", width: "130px",zIndex:'2', height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                onClick={() =>
                  this.handleCountChange("nextweekUppersHitsReduceCount", -1)
                }
                sx={{
                  mr: "8px", width:'32px', height:'32px', cursor:"pointer"
                }}
                data-test-id="nextweekUppersHitsReduceCountMinus"
              />
               <input
                                type="text"
                                data-test-id="nextweekUppersHitsReduceCountText"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                name="nextweekUppersHitsReduceCount"
                                style={{ 
                                    height: '32px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',
                                    fontSize: '16px',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    fontFamily: 'Chromatica-Regular',
                                    width: '51px',

                                }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }} 
                                value={this.state.nextweekUppersHitsReduceCount}
                            />
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                data-test-id="nextweekUppersHitsReduceCountAdd"
                sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
                onClick={() =>
                  this.handleCountChange("nextweekUppersHitsReduceCount", 1)
                }
              />
            </Box>
            {this.state.UppersButtonCalled && this.state.lastWeekUppersHitsCount < this.state.nextweekUppersHitsReduceCount &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Select value less than {this.state.lastWeekUppersHitsCount}
              </p>
            }
          </>
        );;
    }
  };

  uppersReducesHowMuch = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "15px",
          }}
        >
          When you think about reducing, how would you want to measure that?
          Ounces, $$, hits? [choose one]
        </Typography>
        {this.upperMetricValue()}
        {this.selectedUpperMetricValue()}
      </>
    );
  };

  uppersReduceHowOften = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Out of the past 7 days, how many days did you use uppers?
        </Typography>
        <Box sx={{ display: "flex",width: "130px",zIndex:'2', height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr:"8px", width:'32px', height:'32px', cursor:"pointer"
            }}
            onClick={() =>
              this.handleCountChange("pastSevenDaysUppersCount", -1)
            }
            data-test-id="pastSevenDaysUppersCountMinus"
          />
           <input
                                type="text"
                                onChange={(event)=>this.handleInputHowOftenCountChange(event)}
                                style={{ 
                                    height: '32px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    fontFamily: 'Chromatica-Regular',
                                    width: '51px',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',
                                }}
                                name="pastSevenDaysUppersCount"
                                data-test-id="pastSevenDaysUppersCountText"
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }} 
                                value={this.state.pastSevenDaysUppersCount}
                            />
          <Box
            component="img"
            src={button_add.default}
            alt="button_add"
            sx={{ ml: "8px", width:'32px', height:'32px', cursor:"pointer" }}
            onClick={() =>
              this.handleHowOftenCountChange("pastSevenDaysUppersCount", 1)
            }
            data-test-id="pastSevenDaysUppersCountAdd"
          />
        </Box>
        {this.state.pastSevenDaysUppersCount == 0 && this.state.UppersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Out of the NEXT 7 days, how many days do you want to use uppers?
        </Typography>
        <Box sx={{ display: "flex", width: "130px",zIndex:'2', height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr:"8px", width:'32px', height:'32px', cursor:"pointer"
            }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysUppersCount", -1)
            }
            data-test-id="nextSevenDaysUppersCountminus"
          />
         <input
                                type="text"
                                onChange={(event)=>this.handleInputHowOftenCountChange(event)}
                                style={{ 
                                    height: '32px',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    fontFamily: 'Chromatica-Regular',
                                    width: '51px',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',
                                }}
                                name="nextSevenDaysUppersCount"
                                data-test-id="nextSevenDaysUppersCountText"
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }} 
                                value={this.state.nextSevenDaysUppersCount}
                            />
          <Box
            component="img"
            src={button_add.default}
            alt="button_minus"
            sx={{ ml:"8px", width:'32px', height:'32px', cursor:"pointer" }}
            onClick={() =>
              this.handleHowOftenCountChange("nextSevenDaysUppersCount", 1)
            }
            data-test-id="nextSevenDaysUppersCountadd"
          />
        </Box>
        {this.state.UppersButtonCalled && this.state.pastSevenDaysUppersCount < this.state.nextSevenDaysUppersCount &&
          <p
            style={{
              color: "#EB3E3E",
              fontFamily: "Chromatica-Regular",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              margin:0,
              marginTop:'5px'
            }}
          >
            Select value less than {this.state.pastSevenDaysUppersCount}
          </p>
        }
      </>
    );
  };

  uppersStopCompletelyMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "100px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.isTakingBreakUppers == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.isTakingBreakUppers == value ? "#EFF8FB" : "transparent",
      ml: value !== "yes" ? "10px" : "0px",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="isTakingBreakUppers"
        name="isTakingBreakUppers"
        value={this.state.isTakingBreakUppers}
        onChange={this.handleCigarettesReduceTypeChange}
        sx={{ mt: "15px" }}
        data-test-id="isTakingBreakUppers"
      >
        <Box sx={getStyle("yes")}>
          <FormControlLabel
            value={"yes"}
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Yes"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("no")}>
          <FormControlLabel
            value={"no"}
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="No"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedUppersBreakDate = () => {
    const UppersSelectedDatesStyle = (value: string) => ({
      p: "8px 12px",
      border: `1px solid ${
        this.state.uppersBreakSelectedDate == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.uppersBreakSelectedDate == value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="uppersBreakDay"
        name="uppersBreakDay"
        value={this.state.uppersBreakSelectedDate}
        onChange={this.handleUppersBreakDateChange}
        sx={{ mt: "15px", display: "felx",
        justifyContent: "flex-start",
        gap: "10px",zIndex:'2' }}
        data-test-id="uppersBreakDay"
      >
        {this.state.tobaccoBreakDay.map((date, index) => (
          <Box sx={UppersSelectedDatesStyle(date.format("MM/DD/YYYY"))}>
            <FormControlLabel
              key={index}
              value={date.format("MM/DD/YYYY")}
              control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
              label={
                index === 0
                  ? `Today, ${date.format("ddd MMMM D")}`
                  : date.format("ddd, MMMM D")
              }
              componentsProps={{ typography: typographyProps }}
              sx={{ fontWeight: 400, color: "#252525", lineHeight: "24px" }}
            />
          </Box>
        ))}
        <Box sx={UppersSelectedDatesStyle("OtherDay")}>
          <FormControlLabel
            value="OtherDay"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Other day"
            componentsProps={{ typography: typographyProps }}
            sx={{ fontWeight: 400, color: "#252525", lineHeight: "24px" }}
          />
        </Box>
      </RadioGroup>
    );
  };

  uppersStopCompletely = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
            maxWidth: "890px",
            minWidth:'100px'
          }}
        >
          This is a good goal. It's also a really big goal. Some people approach
          this by reducing or using more safely first. Are you sure you want to
          take a break from using uppers at this point?
        </Typography>
        {this.uppersStopCompletelyMeasureType()}
        {this.state.isTakingBreakUppers == "" && this.state.UppersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Please select Yes or No. This field is required!</p>} {this.state.isTakingBreakUppers==='no'  && (
              <p
                style={{ 
                  margin: "0",fontSize: "16px",
                  fontFamily: "Chromatica-Regular",fontWeight: 500,color: "#EB3E3E",
                  lineHeight: "24px",
                  marginTop: "20px",  marginBottom: "20px"}}>Please select a new goal or click on "Yes" to continue.</p>
            )}{this.state.isTakingBreakUppers!=='no' && ( <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Way to commit to taking a break from uppers! When day this week do you
          want to start?
        </Typography>
        {this.selectedUppersBreakDate()}
        {this.state.uppersBreakSelectedDate == "" && this.state.UppersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px', display: this.state.hideDateError ? "none" : ""}}>Date is required!</p>}
        {this.state.uppersBreakSelectedDate == "OtherDay" && 
         <div style={{ marginTop: '20px', alignItems: 'center', borderBottom: '1px solid #E3E3E3', display: 'flex',minWidth:'50px' ,maxWidth: '286px',  }}>
         <input
         data-test-id="popip"
             onClick={() => this.openPopUp(1)}
             type="text"
             value={this.state.uppersCalendarSelectedDate}
             style={{
                 outline: 'none',
                 width: '100%',
                 fontFamily: 'Chromatica-Regular',
                 padding: '10px 0',
                 fontSize: '16px',
                 marginRight: '10px',
                 border: 'none',
                 backgroundColor: 'transparent',
             }}
             readOnly
             placeholder="Choose Day"
         />
         <img
           data-test-id="popimg"
             onClick={() => this.openPopUp(1)}
             alt="Calendar"
             src={calendar.default}
             style={{  width: '24px', cursor: 'pointer', height: '24px' }}
         />
     </div>
        }
          <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openCalender && (

                                    <Card style={{
                                        padding: '25px 10px 15px 10px', width: '400px', display: 'flex',
                                        gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center', borderRadius: '16px', flexDirection: 'column',
                                    }}>

                                        <DatePickerAlcohol onChange={this.handleUppersDataCalendar} navigation={undefined} id={''} onAccept={undefined}  value={this.state.formattedDateInter} onClose={undefined} />
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textTransform: 'none', width: '90px', height: '43px',
                                                border: '1px solid #00A7D1',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                                borderRadius: '8px',
                                                textAlign: 'center',
                                                color: '#0075BF',
                                            }}

                                            data-test-id="closebtn"
                                                onClick={() => this.closePopUp()}
                                            > Back
                                            </Button>
                                            <Button style={{
                                                fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none',background: '#00A7D1', fontFamily: 'Chromatica-Bold', width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
                                                borderRadius: '8px', color: '#FFFFFF',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmUppersCalendarDate()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                        {this.state.dateErrorMessage && (
                          <p
                            style={{
                              color: "#EB3E3E",
                              fontFamily: "Chromatica-Regular",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "24px",
                              margin: "8px 0 0",
                              paddingLeft: "16px",
                            }}
                          >
                            {this.state.dateErrorMessage}
                          </p>
                        )}
                                    </Card>
                                )}
                            </Backdrop>
        {this.state.uppersBreakSelectedDate == "OtherDay" && this.state.UppersButtonCalled && this.state.uppersCalendarSelectedDate?.length < 2 && <p style={{margin:'0', color: "#EB3E3E",
                            fontFamily: "Chromatica-Regular",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "24px",marginTop:'5px', display: this.state.hideDateError ? "none" : ""}}>Date is required!</p>}
                            </> ) 
                            }
      </>
    );
  };

  uppersOthers = () => {

  const isValiduppersOthersText = /^[^a-zA-Z]*$/.test(this.state.uppersOthersTextValue);
    return (
      <>
      <Typography
        sx={{
          mt: "30px",
          zIndex: "2",
          lineHeight: "24px",
          fontWeight: 400,
          fontSize: "16px",
          color: "#252525",
          fontFamily: "Chromatica-Regular",
        }}
      >
        What do you want to see happen with your use?
      </Typography>
      <TextField
        placeholder="Type here..."
        multiline
        value={this.state.uppersOthersTextValue}
        onChange={this.handleInputChange}
        name="uppersOthersTextValue"
        InputProps={{
          sx: {
            "&:after": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:before": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid #E3E3E3",
            },
            ".MuiInputBase-input": {
              paddingBottom: "5px",
            },
          },
          disableUnderline: false,
        }}
        inputProps={{
          style: {
            fontFamily: "Chromatica-Regular",
            fontSize: "16px",
          },
          sx: {
            "&::placeholder": {
              fontFamily: "Chromatica-Bold",
              fontWeight: 700,
              color: "#939393",
              fontSize: "16px",
            },
          },
        }}
        sx={{
          maxWidth: "816px",
          fontSize: "16px",
          fontFamily: "Chromatica-Regular",
          fontWeight: 700,
          mt: "25px",
          lineHeight: "24px",
          color: "#939393",
          minWidth:'50px'
        }}
        rows={5}
        fullWidth
        variant="standard"
      />
     { 
  this.state.UppersButtonCalled && (
    (this.state.uppersOthersTextValue?.length < 1 || isValiduppersOthersText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0', 
        marginTop: '5px'
      }}>
        { 
          this.state.uppersOthersTextValue?.length < 1 
            ? "Text field is required!" 
            : "Please enter valid text!"
        }
      </p>
    )
  )
}

    </>
    );
  };

  selectedUppersReduceState = () => {
    const { upperReduceState } = this.state;

    switch (upperReduceState) {
      case "UppersReduceHowMuch":
        return this.uppersReducesHowMuch();

      case "UppersReduceHowOften":
        return this.uppersReduceHowOften();

      case "Safer/healthier":
        return (
          <Typography
            sx={{
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              mt: "20px",
              zIndex: "2",
            }}
            data-test-id="Safer/healthier"
          >
            Check out our list of safer use strategies{" "}
            <span
              style={{
                fontWeight: 500,
                fontFamily: "Chromatica-Bold",
                borderBottom: "1px solid #252525",
                cursor:"pointer",
              }}
                data-test-id="spanValue2"
              onClick={()=> this.SaferHandle()}
            >
              Here
            </span>{" "}
            to choose your goals for the next week.
          </Typography>
        );

      case "UppersStopCompletely":
        return this.uppersStopCompletely();

      case "Others":
        return this.uppersOthers();

      default:
        return null;
    }
  };


  Uppers = () => {
    return (
      <>
        <>
          <Box
            sx={{
              p: "8px 16px",
              backgroundColor: "#F5F5F5",
              borderRadius: "16px",
              zIndex: "2",
              alignItems: "center",
              gap: "10px",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src={info.default}
              sx={{ width: "24px", height: "24px" }}
              alt="info"
            />
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                alignItems: "center",
                display: "flex",
              }}
            >
              "Uppers" refers to stimulants like [Ritalin, Adderall, meth,
              speed, coke]
            </Typography>
          </Box>
        </>
        {this.upperReduceState()}
        {this.selectedUppersReduceState()}       
 {this.state.isTakingBreakUppers!=='no' &&
        <Button
          fullWidth
          variant="contained"
          onClick={this.moveToDowners}
          data-test-id="moveToDowners"
          sx={{
            fontFamily: "Chromatica-Regular",
            height: "56px",
            mt: "25px",
            mb: "15px",
            maxWidth: "348px",
            padding: "16px",
            borderRadius: "16px",
            textTransform: "none",
            fontSize: "18px",
            fontWeight: 700,
            background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
            lineHeight: "24px",
            minWidth:'50px'
          }}
        >
          <Typography
            sx={{
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            Add Goal
          </Typography>
          <Box
            component="img"
            alt="loginArrow"
            src={loginArrow.default}
            sx={{ ml: "2px" }}
          />
        </Button>
  }
      </>
    );
  }

  DownersReduceState = () => {
    const DownersGetStyle = (value: string) => ({
      height: "40px",
      borderRadius: "32px",
      backgroundColor:
        this.state.downersReduceState === value ? "#EFF8FB" : "transparent",
      p: "8px 12px",
      border: `1px solid ${
        this.state.downersReduceState === value ? "#00A7D1" : "#E3E3E3"
      }`,
      width: "227px",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontWeight: 400,
        fontSize: "16px",
      },
    };

    return (
      <RadioGroup
        sx={{
          mt: "15px",
          display: "felx",
          gap: "10px",
          justifyContent: "flex-start",
        }}
        value={this.state.downersReduceState}
        onChange={(e) => this.setState({ downersReduceState: e.target.value })}
        data-test-id="downersReduceState"
        row
        aria-label="downersReduceState"
        name="downersReduceState"
      >
        <Box sx={DownersGetStyle("downersReduceHowMuch")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="downersReduceHowMuch"
            label="Reduce how much"
            sx={{ color: "#252525", lineHeight: "24px" }}
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetDownersHowOften}
            data-test-id="resetDownersHowOften" 
          />
        </Box>
        <Box sx={DownersGetStyle("downersReduceHowOften")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="downersReduceHowOften"
            label="Reduce how often"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersSafer}
            data-test-id="resetDownersSafer" 
          />
        </Box>

        <Box sx={DownersGetStyle("downersStopCompletely")}>
          <FormControlLabel

            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="downersStopCompletely"
            label="Stop completely"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersOthers}
            data-test-id="resetDownersOthers" 
          />
        </Box>
        <Box sx={DownersGetStyle("Others")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="Others"
            label="Other"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersHowMuch}
            data-test-id="resetDownersHowMuch" 
          />
        </Box>
      </RadioGroup>
    );
  };

  DownersMetricValue = () => {
    const DownersGetStyle = (value: string) => ({
      width: "124px",
      border: `1px solid ${
        this.state.downersMetricValue === value ? "#00A7D1" : "#E3E3E3"
      }`,
      p: "8px 12px",
      height: "40px",
      borderRadius: "32px",
      backgroundColor:
        this.state.downersMetricValue === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        color: "#252525",
        lineHeight: "24px",
        fontWeight: 400,
      },
    };

    return (
      <RadioGroup
        row
       
        value={this.state.downersMetricValue}
        sx={{
          mt: "10px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        onChange={(e) => this.setState({ downersMetricValue: e.target.value })}
        data-test-id="downersMetricValue"
        aria-label="downersMetricValue"
        name="downersMetricValue"
      >
        <Box sx={DownersGetStyle("ounces")}>
          <FormControlLabel

            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            value="ounces"
            componentsProps={{ typography: typographyProps }}
            label="Ounces"
            data-test-id="resetDownersDollarAndHits"
            onClick={this.resetDownersDollarAndHits}
          />
        </Box>
        <Box sx={DownersGetStyle("dollars")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            componentsProps={{ typography: typographyProps }}
            value="dollars"
            label="$$"
            data-test-id="resetDownersOunceAndHits"
            onClick={this.resetDownersOunceAndHits}
          />
        </Box>
        <Box sx={DownersGetStyle("hits")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            label="Hits"
            value="hits"
            componentsProps={{ typography: typographyProps }}
            data-test-id="resetDownersDollarAndOunces"
            onClick={this.resetDownersDollarAndOunces}
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedDownersMetricValue = () => {
    const { downersMetricValue } = this.state;
    const isValidDownersLastWeekText = /^\d*$/.test(this.state.lastWeekDownersDollarsTextValue);
    const isValidDownersNextweekText = /^\d*$/.test(this.state.nextweekDownersDollarsReduceTextValue);

    switch (downersMetricValue) {
      case "ounces":
        return (
          <>
            <Typography
              sx={{
                lineHeight: "24px",
                color: "#252525",
                fontWeight: 400,
                fontFamily: "Chromatica-Regular",
                mt: "20px",
                fontSize: "16px",
                zIndex: "2",
              }}
            >
              Over the last week, how many Ounces did you have on a typical day
              when you used?
            </Typography>
            <Box
              sx={{ display: "flex", height: "30px", mt: "15px" , width: "130px",zIndex:'2'}}
            >
              <Box
                component="img"
                alt="button_minus"
                src={button_minus.default}
                sx={{
                  mr:"8px", width:'32px', height:'32px', cursor:"pointer"
                }}
                data-test-id="lastWeekDownersOuncesCountMinus"
                onClick={() =>
                  this.handleCountChange("lastWeekDownersOuncesCount", -1)
                }
              />
              <input
                                data-test-id="lastWeekDownersOuncesCountText"
                                type="text"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                name="lastWeekDownersOuncesCount"
                                style={{ 
                                    height: '32px',
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    border: '1px solid #E3E3E3',
                                    fontFamily: 'Chromatica-Regular',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',

                                }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                value={this.state.lastWeekDownersOuncesCount}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                      e.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }} 
                            />
              <Box
                component="img"
                alt="button_add"
                sx={{ ml:"8px", width:'32px', height:'32px', cursor:"pointer" }}
                src={button_add.default}
                onClick={() =>
                  this.handleCountChange("lastWeekDownersOuncesCount", 1)
                }
                data-test-id="lastWeekDownersOuncesCountAdd"
              />
            </Box>
            {this.state.lastWeekDownersOuncesCount == 0 && this.state.DownersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex",height: "30px", width: "130px",  mt: "15px", zIndex:'2' }}
            >
              <Box
                src={button_minus.default}
                component="img"
                sx={{
                  mr:"8px", width:'32px', height:'32px', cursor:"pointer"
                }}
                alt="button_minus"
                onClick={() =>
                  this.handleCountChange(
                    "nextweekDownersOuncessReduceCount",
                    -1
                  )
                }
                data-test-id="nextweekDownersOuncessReduceCountMinus"
              />
              <input
              onBlur={(e) => {
                if (e.target.style) {
                  e.target.style.border = '1px solid #E3E3E3';
                }
              }} 
                                data-test-id="nextweekDownersOuncessReduceCountText"
                                type="text"
                                onChange={(event)=>this.handleInputCountChange(event)}
                                name="nextweekDownersOuncessReduceCount"
                                style={{ 
                                  fontSize: '16px',
                                    height: '32px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    fontFamily: 'Chromatica-Regular',
                                    border: '1px solid #E3E3E3',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',
                                }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                                value={this.state.nextweekDownersOuncessReduceCount}
                                
                            />
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                onClick={() =>
                  this.handleCountChange("nextweekDownersOuncessReduceCount", 1)
                }
                data-test-id="nextweekDownersOuncessReduceCountAdd"
                sx={{ height:'32px', ml:"8px", width:'32px', cursor:"pointer" }}
              />
            </Box>
            {this.state.DownersButtonCalled && this.state.lastWeekDownersOuncesCount < this.state.nextweekDownersOuncessReduceCount &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Select value less than {this.state.lastWeekDownersOuncesCount}
              </p>
            }
          </>
        );
      case "dollars":
        return (
          <>
            <Typography
              sx={{
                lineHeight: "24px",
                fontWeight: 400,
                fontFamily: "Chromatica-Regular",
                fontSize: "16px",
                zIndex: "2",
                mt: "20px",
                color: "#252525",
              }}
            >
             Over the last week, how much money did you have on a typical day when you used?
            </Typography>
            <TextField
                multiline
                rows={1}
                placeholder="Type here..."
                fullWidth
                value={this.state.lastWeekDownersDollarsTextValue}
                onChange={this.handleInputChange}
                name="lastWeekDownersDollarsTextValue"
                variant="standard"
                sx={{
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  mt: "20px",
                  maxWidth: "286px",
                  color: "#939393",
                  minWidth:'20px'
                }}
               
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontWeight: 700,
                      fontFamily: "Chromatica-Regular",
                      fontSize: "16px",
                      color: "#939393",
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    height: "58px",
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
              />
{ 
  this.state.DownersButtonCalled && (
    (this.state.lastWeekDownersDollarsTextValue?.length < 1 || !isValidDownersLastWeekText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: 0, 
        marginTop: '5px'
      }}>
        { 
          this.state.lastWeekDownersDollarsTextValue?.length < 1 
            ? "This field is required!" 
            : "Please enter a valid number"
        }
      </p>
    )
  )
}
            <Typography
              sx={{
                fontWeight: 400,
                mt: "20px",
                zIndex: "2",
                color: "#252525",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "Chromatica-Regular",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <TextField
                placeholder="Type here..."
                multiline
                value={this.state.nextweekDownersDollarsReduceTextValue}
                onChange={this.handleInputChange}
                name="nextweekDownersDollarsReduceTextValue"
                InputProps={{
                 
                  sx: {
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                   
                  },
                  disableUnderline: false,
                }}
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      fontFamily: "Chromatica-Regular",
                      fontWeight: 700,
                      color: "#939393",
                    },
                  },
                }}
                sx={{
                  lineHeight: "24px",
                  mt: "20px",
                  maxWidth: "286px",
                  fontSize: "16px",
                  color: "#939393",
                  mb: "10px",
                  fontWeight: 500,
                  fontFamily: "Chromatica-Regular",
                  minWidth:'20px'
                }}
                rows={1}
                fullWidth
                variant="standard"
              />
{ 

  this.state.DownersButtonCalled && (
    (this.state.nextweekDownersDollarsReduceTextValue?.length < 1 || !isValidDownersNextweekText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: 0, 
        marginTop: '5px'
      }}>
        { 
          this.state.nextweekDownersDollarsReduceTextValue?.length < 1 
            ? "This field is required!" 
            : "Please enter a valid number"
        }
      </p>
    )
  )
} 
            {this.state.DownersButtonCalled && Number(this.state.lastWeekDownersDollarsTextValue) < Number(this.state.nextweekDownersDollarsReduceTextValue) &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Amount should be less than {this.state.lastWeekDownersDollarsTextValue}
              </p>
            }
          </>
        );
      case "hits":
        return (
          <>
            <Typography
              sx={{
                fontWeight: 400,
                mt: "20px",
                fontSize: "16px",
                color: "#252525",
                lineHeight: "24px",
                zIndex: "2",
                fontFamily: "Chromatica-Regular",
              }}
            >
              Over the last week, how many Hits did you have on a typical day
              when you used?
            </Typography>
            <Box
              sx={{  height: "30px", mt: "15px",display: "flex", width: "130px",zIndex: "2", }}
            >
              <Box
                src={button_minus.default}
                component="img"
                onClick={() =>
                  this.handleCountChange("lastWeekDownersHitsCount", -1)
                }
                alt="button_minus"
                data-test-id="lastWeekDownersHitsCountMinus"
                sx={{
                  width:'32px', height:'32px', mr:"8px", cursor:"pointer"
                }}
              />
             <input
             data-test-id="lastWeekDownersHitsCountText"
             type="text"
             onChange={(event)=>this.handleInputCountChange(event)}
             name="lastWeekDownersHitsCount"
              onBlur={(e) => {
                if (e.target.style) {
                  e.target.style.border = '1px solid #E3E3E3';
                }
              }} 
                                
                                style={{ 
                                  fontSize: '16px',
                                    height: '32px',
                                    textAlign: 'center',
                                    outline: 'none',
                                    fontFamily: 'Chromatica-Regular',
                                    border: '1px solid #E3E3E3',
                                    color: '#252525',
                                    borderRadius: '4px',
                                    width: '51px',
                                }}
                                value={this.state.lastWeekDownersHitsCount} 
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                            />
              <Box
                src={button_add.default}
                component="img"
                sx={{ ml:"8px", width:'32px', height:'32px', cursor:"pointer" }}
                alt="button_add"
                data-test-id="lastWeekDownersHitsCountAdd"
                onClick={() => this.handleCountChange("lastWeekDownersHitsCount", 1)}
              />
            </Box>
            {this.state.lastWeekDownersHitsCount == 0 && this.state.DownersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                color: "#252525",
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
                fontWeight: 400,
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex", mt: "15px", width: "130px", height: "30px",zIndex: "2", }}
            >
              <Box
                src={button_minus.default}
                component="img"
                onClick={() =>
                  this.handleCountChange("nextweekDownersHitsReduceCount", -1)
                }
                alt="button_minus"
                data-test-id="nextweekDownersHitsReduceCountMinus"
                sx={{
                  mr:"8px", width:'32px', height:'32px', cursor:"pointer"
                }}
              />
             <input
             data-test-id="nextweekDownersHitsReduceCountText"
             type="text"
             onChange={(event)=>this.handleInputCountChange(event)}
             onBlur={(e) => {
               if (e.target.style) {
                 e.target.style.border = '1px solid #E3E3E3';
               }
             }} 
             name="nextweekDownersHitsReduceCount"
                                
             value={this.state.nextweekDownersHitsReduceCount} 
             style={{ 
               fontSize: '16px',
                 height: '32px',
                 textAlign: 'center',
                 outline: 'none',
                 fontFamily: 'Chromatica-Regular',
                 border: '1px solid #E3E3E3',
                 color: '#252525',
                 borderRadius: '4px',
                 width: '51px',
             }}
                                onFocus={(e) => {
                                  if (e.target.style) {
                                    e.target.style.border = '1px solid #E3E3E3';
                                  }
                                }}
                            />
              <Box
                component="img"
                src={button_add.default}
                data-test-id="nextweekDownersHitsReduceCountAdd"
                alt="button_add"
                sx={{ width:'32px', height:'32px', ml:"8px", cursor:"pointer" }}
                onClick={() =>
                  this.handleCountChange("nextweekDownersHitsReduceCount", 1)
                }
              />
            </Box>
            {this.state.DownersButtonCalled && this.state.lastWeekDownersHitsCount < this.state.nextweekDownersHitsReduceCount &&
              <p
                style={{
                  color: "#EB3E3E",
                  fontFamily: "Chromatica-Regular",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  margin:0,
                  marginTop:'5px'
                }}
              >
                Select value less than {this.state.lastWeekDownersHitsCount}
              </p>
            }
          </>
        );
    }
  };

  downersReducesHowMuch = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            mt: "15px",
            fontSize: "16px",
          }}
        >
          When you think about reducing, how would you want to measure that?
          Ounces, $$, hits? [choose one]
        </Typography>
        {this.DownersMetricValue()}
        {this.selectedDownersMetricValue()}
      </>
    );
  };

  downersReduceHowOften = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            mt: "20px",
            zIndex: "2",
            fontSize: "16px",
          }}
        >
          Out of the past 7 days, how many days did you use downers?
        </Typography>
        <Box sx={{ width: "130px",display: "flex",  height: "30px", mt: "15px" ,zIndex:'2'}}>
          <Box
            component="img"
            src={button_minus.default}
            sx={{
              mr:"8px", width:'32px', height:'32px', cursor:"pointer"
            }}
            alt="button_minus"
            onClick={() =>
              this.handleCountChange("pastSevenDaysDownersCount", -1)
            }
            data-test-id="pastSevenDaysDownersCountMinus"
          />
          <input
             data-test-id="pastSevenDaysDownersCountText"
             type="text"
             onChange={(event)=>this.handleInputHowOftenCountChange(event)}
             onBlur={(e) => {
               if (e.target.style) {
                 e.target.style.border = '1px solid #E3E3E3';
               }
             }} 
             name="pastSevenDaysDownersCount"
             onFocus={(e) => {
              if (e.target.style) {
                e.target.style.border = '1px solid #E3E3E3';
              }
            }}            
             value={this.state.pastSevenDaysDownersCount} 
             style={{ 
               fontSize: '16px',
                 height: '32px',
                 textAlign: 'center',
                 outline: 'none',
                 fontFamily: 'Chromatica-Regular',
                 border: '1px solid #E3E3E3',
                 color: '#252525',
                 borderRadius: '4px',
                 width: '51px',
             }}                  
              />
          <Box
            component="img"
            alt="button_add"
            src={button_add.default}
            onClick={() =>
              this.handleHowOftenCountChange("pastSevenDaysDownersCount", 1)
            }
            data-test-id="pastSevenDaysDownersCountAdd"
            sx={{ ml:"8px", width:'32px', height:'32px', cursor:"pointer" }}
          />
        </Box>
        {this.state.pastSevenDaysDownersCount == 0 && this.state.DownersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            fontSize: "16px",
            zIndex: "2",
            mt: "20px",
          }}
        >
          Out of the NEXT 7 days, how many days do you want to use downers?
        </Typography>
        <Box sx={{ display: "flex", width: "130px",zIndex:'2', height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            sx={{
              mr:"8px", width:'32px', height:'32px', cursor:"pointer"
            }}
            alt="button_minus"
            data-test-id="nextSevenDaysDownersCountminus"
            onClick={() =>
              this.handleCountChange("nextSevenDaysDownersCount", -1)
            }
          />
          <input
             type="text"
             data-test-id="nextSevenDaysDownersCountText"
             onChange={(event)=>this.handleInputHowOftenCountChange(event)}
            
             name="nextSevenDaysDownersCount"
             onFocus={(e) => {
              if (e.target.style) {
                e.target.style.border = '1px solid #E3E3E3';
              }
            }}  
            onBlur={(e) => {
              if (e.target.style) {
                e.target.style.border = '1px solid #E3E3E3';
              }
            }}           
            style={{ 
              fontSize: '16px',
               textAlign: 'center',
                height: '32px',
                fontFamily: 'Chromatica-Regular',
                color: '#252525',
                border: '1px solid #E3E3E3',
                borderRadius: '4px',
                width: '51px',
                outline: 'none',
            }}                  
             value={this.state.nextSevenDaysDownersCount} 
              />
          <Box
            component="img"
            src={button_add.default}
            alt="button_minus"
            data-test-id="nextSevenDaysDownersCountadd"
            sx={{ ml:"8px", width:'32px', height:'32px', cursor:"pointer" }}
            onClick={() =>
              this.handleHowOftenCountChange("nextSevenDaysDownersCount", 1)
            }
          />
        </Box>
        {this.state.DownersButtonCalled && this.state.pastSevenDaysDownersCount < this.state.nextSevenDaysDownersCount &&
          <p
            style={{
              color: "#EB3E3E",
              fontFamily: "Chromatica-Regular",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              margin:0,
              marginTop:'5px'
            }}
          >
            Select value less than {this.state.pastSevenDaysDownersCount}
          </p>
        }
      </>
    );
  };

  DownersStopCompletelyMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "100px",
      p: "8px 12px",
      borderRadius: "32px",
      border: `1px solid ${
        this.state.isTakingBreakDowners == value ? "#00A7D1" : "#E3E3E3"
      }`,
      backgroundColor:
        this.state.isTakingBreakDowners == value ? "#EFF8FB" : "transparent",
      height: "40px",
    });

    const typographyProps = {
      sx: {
        fontSize: "16px",
        fontFamily: "Chromatica-Regular",
        color: "#252525",
        lineHeight: "24px",
        fontWeight: 400,
      },
    };

    return (
      <RadioGroup
        row
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        aria-label="isTakingBreakDowners"
        name="isTakingBreakDowners"
        value={this.state.isTakingBreakDowners}
        onChange={this.handleCigarettesReduceTypeChange}
        data-test-id="isTakingBreakDowners"
      >
        <Box sx={getStyle("yes")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            value={"yes"}
            componentsProps={{ typography: typographyProps }}
            label="Yes"
          />
        </Box>
        <Box sx={getStyle("no")}>
          <FormControlLabel
            value={"no"}
            label="No"
            componentsProps={{ typography: typographyProps }}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedDownersBreakDate = () => {
    const DownersSelectedDatesStyle = (value: string) => ({
      border: `1px solid ${
        this.state.downersBreakSelectedDate == value ? "#00A7D1" : "#E3E3E3"
      }`,
      p: "8px 12px",
      height: "40px",
      backgroundColor:
        this.state.downersBreakSelectedDate == value
          ? "#EFF8FB"
          : "transparent",
      borderRadius: "32px",
     
    });
    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        name="DownersBreakDay"
        aria-label="DownersBreakDay"
        onChange={this.handleDownersBreakDateChange}
        data-test-id="DownersBreakDay"       
        value={this.state.downersBreakSelectedDate}
        sx={{
          display: "felx",
          mt: "15px",
          gap: "10px",
          zIndex:'2',
          justifyContent: "flex-start",
        }}

      >
        {this.state.tobaccoBreakDay.map((date, index) => (
          <Box sx={DownersSelectedDatesStyle(date.format('MM/DD/YYYY'))}>
            <FormControlLabel
              componentsProps={{ typography: typographyProps }}
              key={index}
              control={
                <Radio
                  sx={{
                    color: "#E3E3E3",
                    "&.Mui-checked": {
                      color: "#00A7D1",
                    },
                  }}
                />
              }
              label={
                index === 0
                  ? `Today, ${date.format("ddd MMMM D")}`
                  : date.format("ddd, MMMM D")
              }
              value={date.format('MM/DD/YYYY')}
              sx={{ fontFamily: "Chromatica-Regular", fontSize: "16px", fontWeight: 400, color: "#252525", lineHeight: "24px" }}
            />
          </Box>
        ))}
        <Box sx={DownersSelectedDatesStyle("OtherDay")}>
          <FormControlLabel
            componentsProps={{ typography: typographyProps }}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="OtherDay"
            label="Other day"
            sx={{         fontFamily: "Chromatica-Regular",
            fontSize: "16px",fontWeight: 400, color: "#252525", lineHeight: "24px" }}
          />
        </Box>
      </RadioGroup>
    );
  };

  DownersStopCompletely = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            lineHeight: "24px",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Chromatica-Regular",
            mt: "20px",
            maxWidth: "890px",
            zIndex: "2",
            minWidth:'100px',
          }}
        >
          This is a good goal. It's also a really big goal. Some people approach
          this by reducing or using more safely first. Are you sure you want to
          take a break from using downers at this point?
        </Typography>
        {this.DownersStopCompletelyMeasureType()}
        {this.state.isTakingBreakDowners == "" && this.state.DownersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Please select Yes or No. This field is required!</p>}   
            {this.state.isTakingBreakDowners==='no'  && (
              <p
                style={{ 
                  margin: "0",
                  fontSize: "16px",
                  fontFamily: "Chromatica-Regular",fontWeight: 500,color: "#EB3E3E",
                  lineHeight: "24px",
                  marginTop: "20px",
                   marginBottom: "20px"
                  
                }}
              >Please select a new goal or click on "Yes" to continue.</p>
            )}
            {this.state.isTakingBreakDowners!=='no'?( <><Typography sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            mt: "20px",
            zIndex: "2",
            fontSize: "16px",
          }}
        >
          Way to commit to taking a break from your downers use! When day this
          week do you want to start?
        </Typography>
        {this.selectedDownersBreakDate()}
        {this.state.downersBreakSelectedDate == "" && this.state.DownersButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px', display: this.state.hideDateError ? "none" : ""}}>Date is required!</p>}
        {this.state.downersBreakSelectedDate == "OtherDay" && 
       <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px',minWidth:'50px' ,maxWidth: '286px', borderBottom: '1px solid #E3E3E3' }}>
       <input
       data-test-id="popip"
           onClick={() => this.openPopUp(1)}
           type="text"
           placeholder="Choose Day"
           value={this.state.downersCalendarSelectedDate}
           style={{
               outline: 'none',
               width: '100%',
               fontFamily: 'Chromatica-Regular',
               fontSize: '16px',
               border: 'none',
               padding: '10px 0',
               backgroundColor: 'transparent',
               marginRight: '10px',
           }}
           readOnly
       />
       <img
           onClick={() => this.openPopUp(1)}
           src={calendar.default}
           style={{ height: '24px' , cursor: 'pointer', width: '24px', }}
           alt="Calendar"
           data-test-id="popimg"
       />
   </div>
        }
        <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openCalender && (

                                    <Card style={{
                                        padding: '25px 10px 15px 10px', width: '400px', display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', alignItems: 'center',
                                        borderRadius: '16px',
                                        gap: '24px',
                                    }}>

                                        <DatePickerAlcohol onChange={this.handleDownersDataCalendar} navigation={undefined} id={''} onAccept={undefined}  value={this.state.formattedDateInter} onClose={undefined} />
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                width: '90px', height: '43px', background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none',
                                                border: '1px solid #00A7D1',borderRadius: '8px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',                      
                                            }}

                                                onClick={() => this.closePopUp()}
                                                data-test-id="closebtn"
                                            > Back
                                            </Button>
                                            <Button style={{
                                                background: '#00A7D1',                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmDownersCalendarDate()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                        {this.state.dateErrorMessage && (
                          <p
                            style={{
                              color: "#EB3E3E",
                              fontFamily: "Chromatica-Regular",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "24px",
                              margin: "8px 0 0",
                              paddingLeft: "16px",
                            }}
                          >
                            {this.state.dateErrorMessage}
                          </p>
                        )}
                                    </Card>
                                )}
                            </Backdrop>
{this.state.downersBreakSelectedDate == "OtherDay" && this.state.DownersButtonCalled && this.state.downersCalendarSelectedDate?.length < 2  && <p style={{margin:'0', color: "#EB3E3E",
                            fontFamily: "Chromatica-Regular",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "24px",marginTop:'5px', display: this.state.hideDateError ? "none" : ""}}>Date is required!</p>}</>):''}

      </>
    );
  };

  DownersOthers = () => {
  const isValidDownersOthersText = /^[^a-zA-Z]*$/.test(this.state.DownersOthersTextValue);

    return (
      <>
      <Typography
        sx={{
          mt: "30px",
          lineHeight: "24px",
          zIndex: "2",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Chromatica-Regular",
          color: "#252525",
        }}
      >
        What do you want to see happen with your use?
      </Typography>
      <TextField
        placeholder="Type here..."
        multiline
        value={this.state.DownersOthersTextValue}
        onChange={this.handleInputChange}
        name="DownersOthersTextValue"
        InputProps={{
          sx: {
            "&:before": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:after": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid #E3E3E3",
            },
            ".MuiInputBase-input": {
              paddingBottom: "5px",
            },
          },
          disableUnderline: false,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "#939393",
              fontWeight: 700,
              fontSize: "16px",
              fontFamily: "Chromatica-Bold",
            },      
          },
          style: {
            fontFamily: "Chromatica-Regular",
            fontSize: "16px",
          },
        }}
        rows={5}
        fullWidth
        variant="standard"
        sx={{
          maxWidth: "816px",
          fontSize: "16px",
          fontFamily: "Chromatica-Regular",
          fontWeight: 700,
          mt: "25px",
          lineHeight: "24px",
          color: "#939393",
          minWidth:'50px'
        }}
      />
      { 
  this.state.DownersButtonCalled && (
    (this.state.DownersOthersTextValue?.length < 1 || isValidDownersOthersText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: 0, 
        marginTop: '5px'
      }}>
        { 
          this.state.DownersOthersTextValue?.length < 1 
            ? "Text field is required!" 
            : "Please enter valid text!"
        }
      </p>
    )
  )
}
    </>
    );
  };

  selectedDownersReduceState = () => {
    const { downersReduceState } = this.state;

    switch (downersReduceState) {
      case "downersReduceHowMuch":
        return this.downersReducesHowMuch();

      case "downersReduceHowOften":
        return this.downersReduceHowOften();

      case "Safer/healthier":
        return (
          <Typography
            sx={{
              color: "#252525",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
              mt: "20px",
              zIndex: "2",
            }}
            data-test-id="Safer/healthier"
          >
            Check out our list of safer use strategies{" "}
            <span
            data-test-id="spanValue3"
             onClick= {()=> this.SaferHandle() }
              style={{
                fontWeight: 500,
                fontFamily: "Chromatica-Bold",
                borderBottom: "1px solid #252525",
                cursor:"pointer",
              }}
            >
              Here
            </span>{" "}
            to choose your goals for the next week.
          </Typography>
        );

      case "downersStopCompletely":
        return this.DownersStopCompletely();

      case "Others":
        return this.DownersOthers();

      default:
        return null;
    }
  };




  Downers = () => {
    return (
      <>
        <>
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              p: "8px 16px",
              zIndex: "2",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              component="img"
              src={info.default}
              alt="info"
              sx={{ width: "24px", height: "24px" }}
            />
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#252525",
                fontSize: "16px",
                alignItems: "center",
                display: "flex",
              }}
            >
              "Downers" refers to drugs that cause a depressant effect, like
              [Oxy, fentanyl, Xanax, Kratom]
            </Typography>
          </Box>
        </>
        {this.DownersReduceState()}
        {this.selectedDownersReduceState()}
      {  (this.state.isTakingBreakDowners !== 'no')  &&
        <Button
          variant="contained"
          
          sx={{
            height: "56px",
            fontFamily: "Chromatica-Regular",
            mb: "15px",
            mt: "25px",
            maxWidth: "348px",
            padding: "16px",
            background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
            borderRadius: "16px",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "24px",
            textTransform: "none",
            minWidth:'50px'
          }}
          onClick={this.moveToOthers}
          data-test-id="moveToOthers"
          fullWidth
        >
          <Typography
            sx={{
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            Add Goal
          </Typography>
          <Box
            alt="loginArrow"
            component="img"
            src={loginArrow.default}
            sx={{ ml: "2px" }}
          />
        </Button>
  }
      </>
    );
  }

  selecttedTab = () => {
    const { selectedTab } = this.state;
    const renderSelectedTabContent = () => {
      if (selectedTab === "tobaccoOrNicotine") {
        return this.Tobacco();
      } else if (selectedTab === "uppers") {
        return this.Uppers();
      } else if (selectedTab === "downers") {
        return this.Downers()
      }
      return null;
    };
    return (
      <React.Fragment>
        {renderSelectedTabContent()}
        {selectedTab === "alcohol" && (
          <>
          <AlcohalSubstance existingData={{
            selectedValue: this.state.selectedValue,
            selectedValueOption: this.state.selectedValueOption,
            drinkCount: this.state.drinkCount,
            drinkCountSecond: this.state.drinkCountSecond,
            drinkCountOften: this.state.drinkCountOften,
            drinkCountOftenSecond: this.state.drinkCountOftenSecond,
            selectedDate: this.state.selectedDate,
            OthersResponse: this.state.OthersResponse,
          }} updateAns={this.updateAlcohol} navigation={undefined} id={""} updateOther={undefined} safeValueOther={this.alcoholSafer} />
           </>
        )}
        {!this.state.isLoading && selectedTab === "cannabis" && (
          <>
           <Cannabis existingData={{
            selectedValue: this.state.selectedValue,
            selectedValueSubOption: this.state.selectedValueSubOption,
            selectedValueOption: this.state.selectedValueOption,
            drinkCount: this.state.drinkCount,
            drinkCountSecond: this.state.drinkCountSecond,
            drinkCountOften: this.state.drinkCountOften,
            drinkCountSecondOften: this.state.drinkCountOftenSecond,
            selectedDate: this.state.selectedDate,
            OthersResponse: this.state.OthersResponse,
            formattedDateInter: this.state.formattedDateInter,
            textdollars: this.state.textdollars,
            textdollarssecond: this.state.textdollarssecond,
            CannabisSecond: this.state.CannabisSecond,
            cannabisCount: this.state.cannabisCount,
          }} updateData={this.updateShareData} navigation={undefined} id={""} saferValue={this.saferHandleValue}/>
           </>
        )}
         {selectedTab === "Others" && (
           <OtherSubstance existingData={{}} updateOther={this.updateOtherData} navigation={undefined} id={""} updateAns={undefined} safeValueOther={undefined}/>
        )}
      </React.Fragment>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Backdrop open={this.state.isLoading} style={{ color: '#fff', zIndex: 10 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        ref={this.scrollRef}
        container
        sx={{
          backgroundColor: "#F0F0F0",
          padding: "10px",
          overflowY: "scroll",
        }}
        id="modal-content"
      >
        <Grid
          item
          xs={12}
          sx={{
            height: "88px",
            position: "relative",
            borderRadius: "16px",
            backgroundColor: "#0F4257",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              gap: "20px",
              "@media (max-width: 450px)": {
                gap: "10px",
              }
            }}
          >
            <Box
              sx={{
                fontSize: "clamp(25px, 5vw, 40px)",
                fontFamily: "Josefin Sans",
                fontWeight: 300,
                color: "#ffffff",
              }}
            >
              <Box
                component="span"
                sx={{
                  marginBottom: "4px",
                  color: "#EBFF00",
                  fontWeight: 700,
                  fontSize: "clamp(25px, 5vw, 40px)",
                  fontFamily: "Josefin Sans",
                  marginLeft: {
                    xs: '17px', 
                    md: '48px',
                  },
                  lineHeight: "40px",
                }}
                >
                  Well
                </Box>
              Spring
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
                lineHeight: "20px",
                color: "#FFFFFF",
                mt: "3px",
                fontSize: "clamp(14px, 2vw, 20px)",
                "@media (max-width: 590px)": {
                  width: "40%",
                  wordBreak: "break-word",
                }
              }}
            >
              Hey, {this.state.userName}
            </Typography>
          </Box>
          <Box
            component="img"
            src={menuIcon.default}
            onClick={() => {
              this.hamburgerMenu();
            }}
            alt="menuIcon"
            sx={{
              position: "absolute",
              top: "50%",
              right: "2.5%",
              transform: "translate(-50%, -50%)",
              cursor:"pointer",
            }}
            data-test-id="hamburgerMenu"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            minHeight:
              this.state.selectedTab === "tobaccoOrNicotine" &&
              this.state.tobacoReduceState == "reduceHowMuch"
                ? "100vh"
                : "87vh",
            position: "relative",
            mt: "10px",
            height: "auto",
            "@media (max-width: 1025px) and (min-width: 800px)": {
              minHeight: "91vh",
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 4,
              paddingLeft:{
                sm: '58px',
                xs:'20px' },
                paddingRight:{
                  xs:'20px' }
            }}
          >
            <Box
              component="img"
              src={arrow.default}
              alt="Logo"
              onClick={() => {
                this.substanceGoals();
              }}
              sx={{ position: "absolute", top: 30,  left:{sm: 60, xs:20 }}}
              data-test-id="arrowIcon"
            />
            <Typography
              onClick={() => {
                this.substanceGoals();
              }}
              sx={{
                position: "absolute",
                top: 25,
                left:{sm: 80, xs:40 },
                cursor:"pointer",
                fontFamily: "Chromatica-Bold",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 500,
              }}
              data-test-id="BackButton"
            >
              Back
            </Typography>
            <Box
              component="img"
              src={localStorage.getItem('NewUseFound') =='Yes'?textLabel.default:image3.default}
              alt="Logo"
              sx={{
                position: "absolute",
                top: 42,
                left: 58,
                zIndex: "1",
                height: "180px",
                width: "120px",
              }}
            />
            <Box
              component="img"
              src={substanceBottom.default}
              alt="substanceBottomImg"
              sx={{ position: "absolute", right: 0, bottom: 0 , display:{ xs:'none', md:'block'}}}
            />
            <Typography
              sx={{
                color: "#00A7D1",
                fontFamily: "Josefin Sans",
                lineHeight: "32px",
                fontWeight: 600,
                fontSize: "32px",
                zIndex: "2",
                mt: "145px",
              }}
            >
              {configJSON.substanceRelatedGoalsText}
            </Typography>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "10px",
                zIndex: "2",
              }}
            >
              It's great that you're thinking of ways to reduce your risks.
              You're worth it!
            </Typography>

            <Box
              sx={{
                width: "100%",
                bgcolor: "#F7F7F7",
                mt: "20px",
                mb: "20px",
                zIndex: "2",
              }}
            >
              <Tabs
                data-test-id="selectedTab"
                value={this.state.selectedTab}
                scrollButtons="auto"
                variant="scrollable"
                sx={{
                  "& .MuiTab-root": {
                    padding: "8px 16px",
                    marginRight: "4px",
                    color: "black",
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Chromatica-Regular",
                    textTransform: "none",
                    "&.Mui-selected": {
                      zIndex: 1,
                      margin: 0,
                      bgcolor: "white",
                      color: "black",
                      borderRadius: "10px 10px 0 0",
                      border: "1px solid #E5E5E5",
                      fontWeight: 700,
                      borderBottom: "0px",
                    },
                    "&:not(.Mui-selected)": {
                      margin: 0,
                      borderBottom: "1px solid #E5E5E5",
                    },
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                  "& .MuiTabScrollButton-root": {
                    display: "none",
                  },
                  "& .MuiTab-iconWrapper": {
                    marginRight: "15px",
                  },
                  "& .css-69z67c-MuiTabs-scroller":{
                    overflow:'clip'
                  }
                }}
                onChange={this.handleTabChange}
              >
               {this.checkCategoryAvailability("Alcohol") &&
              <Tab
              disabled={this.state.selectedTab !== 'alcohol' &&
                localStorage.getItem("dashboardUser") === "true"
              }
              label="Alcohol"
              icon={
                <Box component="img" src={alcohol.default} alt="alcohol" />
              }
              iconPosition="start"
              value="alcohol"
              sx={{ margin: 0, borderLeft: "0 !important" }}
            />}
                {this.checkCategoryAvailability("Cannabis") &&
                <Tab
                  label="Cannabis"
                  disabled={this.state.selectedTab!='cannabis' &&
                    localStorage.getItem("dashboardUser") === "true"
                  }
                  icon={
                    <Box
                      component="img"
                      src={cannabis.default}
                      alt="cannabis"
                    />
                  }
                  iconPosition="start"
                  value="cannabis"
                  sx={{ margin: 0 }}
                />
                 }
                 {this.checkCategoryAvailability("Tobacco or Nicotine") &&
                <Tab
                disabled={this.state.selectedTab!='tobaccoOrNicotine' &&
                  localStorage.getItem("dashboardUser") === "true"}
                  label="Tobacco or Nicotine"
                  icon={
                    <Box component="img" src={tobacco.default} alt="tobacco" />
                  }
                  iconPosition="start"
                  value="tobaccoOrNicotine"
                  sx={{ margin: 0 }}
                />}
                {this.checkCategoryAvailability("Uppers") &&
                <Tab
                disabled={this.state.selectedTab!='uppers' &&
                  localStorage.getItem("dashboardUser") === "true"}
                  label="Uppers"
                  icon={
                    <Box component="img" src={uppers.default} alt="uppers" />
                  }
                  iconPosition="start"
                  value="uppers"
                  sx={{ margin: 0 }}
                />}
                 {this.checkCategoryAvailability("Downers") &&
                <Tab
                disabled={this.state.selectedTab!='downers' &&
                  localStorage.getItem("dashboardUser") === "true"} 
                  label="Downers"
                  icon={
                    <Box component="img" src={downers.default} alt="downers" />
                  }
                  iconPosition="start"
                  value="downers"
                  sx={{ margin: 0 }}
                />}
                 {this.checkCategoryAvailability("Others") &&
                <Tab
                disabled={this.state.selectedTab!='Others' &&
                  localStorage.getItem("dashboardUser") === "true"}
                  label="Others"
                  icon={
                    <Box component="img" src={others.default} alt="others" />
                  }
                  iconPosition="start"
                  value="Others"
                  sx={{ margin: 0 }}
                />}
              </Tabs>
            </Box>
            {this.selecttedTab()}
          </Box>
        </Grid>
      </Grid>
      </>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
