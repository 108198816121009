import React from "react";
// Customizable Area Start
import VisualAnalytic from "../../visualanalytics/src/VisualAnalytic.web";
import { styled } from "@mui/material/styles"; 
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepConnector from "@mui/material/StepConnector";
import { award_3, award_a4, award_a5, Menu_M,ThumbBoy_b, wave_wave,photo_photo, wave_user } from "./assets";
import { 
  Box, 
  Grid, 
  IconButton, Button, Paper, Backdrop, CircularProgress } from "@mui/material";
import "../../../blocks/dashboard/assets/style.css"
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import "../../../blocks/dashboard/assets/style.css"
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LabelList,
  ReferenceLine,
} from 'recharts';

const StepIconste = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
 
  "@media (max-width: 420px)": {
    width:60,
    height: 60,
  },
  zIndex: 2,
  color: "#fff",
  width:80,
  height: 80,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgb(204, 204, 204)",
  border: `2px solid ${completed ? '#FFEFAC' : '#fff'}`,
  boxShadow: "0 4px 10px 0 rgb(204, 204, 204)", 
  ...(completed && {
    backgroundColor: "#F3A622",
    boxShadow: "0 4px 10px 0 #F3A622", 
  }),
}));
const ColorlibConnector = styled(StepConnector)({
  zIndex: "auto",
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  left: 0,
  right: 0,
  display: 'block', // Display block as per your CSS
  borderColor: 'white',
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  borderRadius:"36px",
  alternativeLabel: {
    top: 22,
    marginRight:'10px',
    marginLeft:'10px',
    left: "-170px",
    right: "19px",
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
});

const ColorlibConnectorSafer = styled(StepConnector)({
  zIndex: "auto",
  display: 'block', // Display block as per your CSS
  borderColor: 'white',
  left: 0,
  right: 0, // New border color
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  borderRadius:"36px",
  alternativeLabel: {
    left: "-170px",
    top: 22,
    right: "19px",
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
});

const ColorlibStepIcon = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
    zIndex: 2,
    "@media (max-width: 420px)": {
      width:60,
      height: 60,
    },
    color: "#fff",
    border: `2px solid ${completed ? '#FFEFAC' : '#fff'}`,
    width:80,
    height: 80,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(204, 204, 204)",
    boxShadow: "0 4px 10px 0 rgb(204, 204, 204)",
    ...(completed && {
      backgroundColor: "#F3A622",
      boxShadow: "0 4px 10px 0 #F3A622", 
    }),
  }));

const WhiteBackgroundContainer = styled('div')({
  background: 'white', 
  padding: '2rem',
  height:"auto",
  marginLeft:"0.7rem",
  marginRight:"0.7rem",
  borderRadius:"16px" ,

});

const StyledButton = styled(Button)({
  background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
color: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  fontWeight: "bold",
  textTransform: "none",
  width:'348px',
  "&:hover": {
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)"
  }
});


 
// Customizable Area End
import DashboardControllerInformation, {
  Props,
  configJSON
} from "./DashboardControllerInformation.web";
import moment from "moment";



export default class DashboardInformation extends DashboardControllerInformation {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


   icons = [
     <img src={award_3.default} alt="awrad_award"/>,
     <img src={award_a4.default} alt="awrad_award"/>,
     <img src={award_a5.default} alt="awrad_award"/>,
   ];
   headerMiddle = ()=>{
    return (
      <>
        <Grid
          item
          sx={{
           ...webStyles.wholeGridStyle,   
            
           "@media (min-width: 400px) and (max-width: 500px)": {
            width: '95.5% !important',
          },
          }}
        >
          <Box
                        sx={{                  
                          ...webStyles.wholedivStyle}}>
                        <Box
                            sx={{
                              ...webStyles.gapM,
                              "@media (max-width: 450px)": {
                                gap: "10px",
                              }
                            }}
                        >
                            <Box
                              data-test-id="parentwell"
                              sx={{
                                ...webStyles.parentWell,
                              }}
                            >
                                <Box
                                  component="span"
                                  sx={{
                                    ...webStyles.well__wellStyle,
                                    marginLeft: {
                                      xs: '17px !important', 
                                      md: '48px !important',
                                    },
                                  }}
                                >
                                    Well
                                </Box>
                                Spring
                            </Box>
                            <Typography
                            data-test-id="fontsize"
                                sx={{
                                  ...webStyles.homeStylemenu,
                                  "@media (max-width: 590px)": {
                                    width: "40%",
                                    wordBreak: "break-word",
                                  }
                                }}
                            >
                                Hey, {this.state.userName}</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            onClick={() => { this.hamburgerMenux() }}
                            component="img"
                            sx={{
                              cursor: "pointer",
                              marginRight: "50px",
                              "@media (max-width: 530px)": {
                                marginRight: "20px !important"},
                              "@media (min-width: 531px) and (max-width:1200px)": {
                                marginRight: "30px !important"}
                            }}
                            alt="menuIcon"      
                        />
                    </Box>
        </Grid>
      </>
    )
   }
 nextStepperFunc = () =>{
  return (
    <>
     <Grid item md={6} lg={6} sm={12} sx={{ display: "-webkit-flex", width: "100%", position: "relative", zIndex: 2 }}>
  <PaperHead style={{ display: "flex", flexDirection: "column", paddingBottom:'10px', width: "100%", flex: 1, justifyContent: "space-between", boxSizing: "border-box", height: "100%",minHeight:'300px', background: "#DFEDF0", borderRadius: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", flexGrow: 1 }}>
      <Box style={{ padding: "2rem" }} >
        <Typography sx={webStyles.goalStyles}>Safer-Use Strategies</Typography>
      </Box>
      <Stepper
        alternativeLabel
        activeStep={this.state.activeSUSStep}
        connector={<ColorlibConnectorSafer />}
        sx={this.state.saferResponse?.safer_user_strategies?.length > 0 ? webStyles.stepperSUSStyle : webStyles.stepperSUSZero}>
        {
        this.state.saferResponse?.safer_user_strategies?.length > 0 ? (
          this.state.saferResponse?.safer_user_strategies?.map((goal: any, index: any) => (
            <Step key={goal?.id}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIconste {...props} completed={goal?.status === "achieved"}>
                    {this.icons[index]}
                  </StepIconste>
                )}
              >
                <span style={{...webStyles.labelstylesteppers,wordBreak:'break-word'}}>{goal?.title}</span>
              </StepLabel>
              <ColorlibConnectorSafer />
            </Step>
          ))
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '48px',
            height: '100%',
            fontFamily: 'Chromatica-Bold',
            fontSize: '64px !important',
            fontWeight: 700,
            padding: '20px',
            marginTop:'auto'
          }}>
            No task found
          </div>
        )
        }
      </Stepper>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: { sm: "row", xs: "column", md: "column", lg: "row" },
        "@media (max-width: 420px)": {
          display: 'table !important',
          width:'100%',
        },
        marginTop: "auto"
      }}>
        <div style={{
          marginTop: "0.2rem",
          padding: "1.3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Box sx={{
            background: "#B4E9F4",
            width: { xs: "195px", sm: "250px", md: "100%" },
            borderRadius: "40px",
            padding: { xs: "8px 12px", sm: "10px 14px", md: "12px 16px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}>
            <Typography sx={{...webStyles.threebythreeStyles,textTransform: 'capitalize',textAlign: "center", fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
            }}>{this.state.saferUserStrategyTotal.achieved}<Box component="span" style={{ color: "#59777D" }}>/{this.state.saferUserStrategyTotal.total}</Box> Strategies Completed!</Typography>
          </Box>
        </div>
        <div >
        <Typography sx={{...webStyles.viewStyle, paddingRight: "2rem", "@media (max-width: 600px)": {  paddingRight:'0',textAlign:'center'}, "@media (min-width: 900px) and (max-width: 1200px)": { paddingRight:'0' }}} onClick={this.goToSaferseStrategies.bind(this) }>{configJSON.viewMore}</Typography>
        </div>
      </Box>
      
    </Box>
  </PaperHead>
</Grid>
    </>
  )
 }
 CustomTooltip = (props:any) => {
  const { active, payload, label } = props; 

  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload; 
    return (
      <div style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',

        padding: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        ...webStyles.tooltipStyle, 
      }}>
        <p style={{ margin: 0 }}>
        {`${dataPoint?.date ? moment(dataPoint.date).format("MMMM Do") : 'N/A'}`}
  </p>
        <p style={{ margin: 0 }}>{`Score: ${dataPoint?.harm || '0'}`}</p>
      </div>
    );
  }
  return null;
};

CustomLegend = ({ payload }:any) => {
  return (
    <>
  <div style={{textAlign:"center",margin:"1rem 0"}}> 
  {payload.map((entry:any, index:any) => (
        <li 
          key={`item-${index}`} 
          style={{ margin: '0 10px',   fontFamily: 'Chromatica-Regular',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '20px',color:'#00A7D1'}} 
        >
        Months
        </li>
      ))}
  </div>
      </>
  
  );
};
  // Customizable Area End

  render() {
    // Customizable Area Start
    let graphDataMsg = localStorage.getItem("GraphData");
    let accountCreated: any = {};
    
    try {
      accountCreated = graphDataMsg ? JSON.parse(graphDataMsg) : {};
    } catch (e) {
      
    }
    
    let messageUp = accountCreated?.message || "";
    
    const updatedData = this.state.dataAns?.map((item: { date: string | number | Date; harm: any; }) => ({
      date: typeof item.date === 'string' ? new Date(item.date?.replace(/-/g, '/'))?.getTime() : new Date(item.date)?.getTime(),
      harm: item.harm,
    }));
    const dateMin = Math.min(...updatedData?.map((d: any) => new Date(d.date).getTime()));
    const dateMax = Math.max(...updatedData?.map((d: any) => new Date(d.date).getTime()));
    return (
      //Merge Engine DefaultContainer
  <>
    <Backdrop style={{ color: '#fff', zIndex: 10 }} open={this.state.isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  <Dialog open={this.state.popupShow && !this.state.isAssessemtnFilled}
          PaperProps={{
            style: {
              borderRadius: 24, maxWidth: 692,
              overflowX: "hidden",boxShadow:"none"
            },
          }}
        >
          <DialogTitle>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left',
                  alignItems: 'left',
                  width: '100%',
                  padding: '2rem'

                }}
              >


                <Typography sx={webStyles.Congratulationsstyleinasfers} className="decreasefont">
                  Harm Reduction Questionnaire
                </Typography>

              </Box>
              <Box sx={{ paddingLeft: '2rem' }}>
                <Typography sx={webStyles.subtancesStyle} className="middletext">
                  WellSpring can help you reduce your substance-related harm. Please answer <br />the following questions so we can figure out your baseline and help you<br /> track your progress over time. It's sometimes hard to look at substance-<br />related harm, but that's why you're here. You got this.
                </Typography>
              </Box>
            </Box>


          </DialogTitle>
          <Box
        
            sx={{
              width: '100%',
              height: '389px',
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), url(${photo_photo.default})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '0 0 24px 24px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
            }}
            className="heightdown"
          >
            <Typography
              sx={{
                fontFamily: 'Chromatica-Regular',
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '24px',
                color: "#ffffff",
                textAlign: 'center',
                marginBottom: '16px',
                textShadow: "2px 3px 10px black",
              }}
              className="minutsstyele"
            >
              This should take you about 5 minutes
            </Typography>

            <Box sx={{ textAlign: 'center' }}>
              <StyledButton
              className="startqustmedia"
                onClick={() => this.goToassessment()}
                data-test-id="popupshow"
              >
                <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>
                  Start Questionnaire
                </span>
              </StyledButton>
            </Box>
          </Box>

        </Dialog>
        <Dialog
          open={this.state.popupShow && this.state.isAssessemtnFilled}
          PaperProps={{
              style: { borderRadius: 24,overflow:"hidden",boxShadow:"none" },
              background: "#0F4257"
          }}
        >
          <DialogTitle sx={{ background: "#0F4257" }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <div style={{ display: "flex", alignItems: 'center', justifyContent: "flex-start" }}>
                <Typography
                  sx={{
                    ...webStyles.SprinStylethird,
                    fontSize: { xs: '25px', sm: '30px', md: '40px' },
                    "@media (max-width: 500px)": {
                      fontSize: '25px !important',
                    },
                    "@media (min-width:501px) and (max-width: 580px)": {
                      fontSize: '30px !important',
                    },
                  }}
                >
                  Well
                </Typography>
                <Typography
                  sx={{
                    ...webStyles.wellStylethird,
                    fontSize: { xs: '25px', sm: '30px', md: '40px' }
                  }}
                >
                  Spring
                </Typography>
              </div>
              <Typography
                sx={{
                  fontFamily: 'Chromatica-Regular',
                  fontSize: '20px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  color:'white',
                  marginTop: "1.2rem",
                }}
              >
                Go to Harm Reduction Questionnaire to track your 
                progress every two weeks.
              </Typography>
            </Box>
            <Box style={{ ...webStyles.flexBoxthird, marginTop: "1.3rem" }}>
              <Box>
                <Button
                  sx={{ ...webStyles.harmChartTextStyle, cursor: "pointer", minHeight: "56px" }}
                  onClick={this.goToassessment.bind(this)}
                >
                  <Typography sx={{ ...webStyles.achievegoalstext, color: "white", fontSize: "18px", textTransform: "capitalize" }}>
                    Start Questionnaire
                  </Typography>
                </Button>
              </Box>
            </Box>
          </DialogTitle>
        </Dialog>
   <Grid
        item
        style={{
          background: '#f0f0f0',
        }}
      >
      {this.headerMiddle()}
        </Grid>
      
        <WhiteBackgroundContainer>
        <Box
          ref={this.scrollRef}
                  component="img"
                  src={wave_wave.default}
                  alt="Logo"
                  sx={{
                    position: "absolute",
                    top:'111px',
                    left: 0,
                    width: '100%',
                    height: 'auto',
                    maxWidth: '600px',
                    display:{
                      md:'block',
                      xs:'none',
                  }
                  }}
                />
          <Box style={{ marginBottom: "2rem" }}>
            <Typography style={{...webStyles.YourStyles,position: "relative", zIndex: 2 }}>Your Dashboard</Typography>
          </Box>
          <Grid container spacing={4} style={{ display: 'flex', alignItems: 'stretch' }}>
            <Grid item md={6} lg={6} sm={12} style={{ display: "-webkit-flex", position: "relative", zIndex: 2, width: "100%" }}>
            
              <Paper style={{display:'flex',flexDirection:'column', flex: 1, justifyContent: "space-between", boxSizing: "border-box", paddingBottom:'10px', width: "100%", minHeight:'300px', height: '100%',  background: "#F1F7D0", borderRadius: "16px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", height: "100%", flexGrow: 1 }}>
                <Box style={{ padding: "2rem" }} >
                  <Typography sx={webStyles.goalStyles}>Goals</Typography>
                </Box>
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeGoalStep}
                  connector={<ColorlibConnector />}
                  sx={this.state.goalsResponse?.goals?.length>0?webStyles.stepperStyle:webStyles.stepperZero}
                >
                  {this.state.goalsResponse?.goals?.length>0? (
                  this.state.goalsResponse?.goals?.map((goal: any, index: any) => (
                    <Step key={goal?.id}>
                      <StepLabel
                        StepIconComponent={(props) => (
                          <ColorlibStepIcon {...props} completed={goal?.status === "achieved"}>
                            {this.icons[index]}
                          </ColorlibStepIcon>
                        )}
                      >
                        <span style={{...webStyles.labelstylesteppers,wordBreak:'break-word'}}>{this.getGoalName(goal)}</span>
                      </StepLabel>
                      <ColorlibConnector />
                    </Step>
                  ))):<div style={{
                    display: 'flex',
                    fontSize: '64px !important',
                    fontWeight: 700,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '48px',
                    height: '100%',               
                    fontFamily: 'Chromatica-Bold',
                    padding: '20px',
                    marginTop:'auto'
                  }}>
                    No task found </div>
                }
                </Stepper> 
                    <Box
                      sx={{
                        display: "flex",
                        marginTop:'auto',
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap:"0",
                        flexDirection: { sm: "row", xs: "column", md: "column", lg: "row" },
                        "@media (max-width: 420px)": {
                          display: 'table !important',
                          width:'100%'
                        }
                      }}
                    >
                      <div style={{
    padding: "1.3rem",
    marginTop: "0.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}>
                        <Box sx={{
    width: { xs: "195px", sm: "250px", md: "100%" }, 
    background: "#CFE472",
    padding: { xs: "8px 12px", sm: "10px 14px", md: "12px 16px" },
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  }}>
                            <Typography sx={{...webStyles.threebythreeStyles,textTransform: 'capitalize', fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
      textAlign: "center",}}>{this.state.goalsTotalTask.achieved}<Box component="span" style={{ color: "#778916" }}>/{this.state.goalsTotalTask.total}</Box> Goals Completed!</Typography>
                        </Box>
                      </div>
                      <Box>
                      <Typography data-test-id="checkid" sx={{...webStyles.viewStyle, paddingRight: "2rem", "@media (max-width: 600px)": {  paddingRight:'0',textAlign:'center'}, "@media (min-width: 900px) and (max-width: 1200px)": { paddingRight:'0' }
}} onClick={this.goTogoalsScreen.bind(this) }>{configJSON.viewMore}</Typography>
                      </Box>
                    </Box>
                </Box>
              </Paper>
            </Grid>
          {this.nextStepperFunc()}
          </Grid>
          <Grid container style={{ marginTop: "3rem",position:'relative' }} spacing={2}>
            <Grid item md={12} lg={12}>           
              <Paper
                sx={{
                  display: "flex",
                  background: "#5C61DB",
                  borderRadius: "16px",
                  height: "124px",
                  "@media (max-width: 1099px)": {
                    height: "145px",
                  },
                  "@media (max-width: 1045px)": {
                    height: "150px",
                  },
                  "@media (max-width: 980px)": {
                    height: "auto",
                  },
                }}
              >
                <Box sx={webStyles.flexpropertys}>
                  <Box sx={{ ...webStyles.flexpropertys, display: "flex",
                  justifyContent: "space-between", alignItems: "flex-end", position: "relative" }}>
                    <Box sx={{ flex: 1, marginLeft: { xs: "0", sm: "1rem" }}}>
                      {messageUp === "You've reduced your substance related harm."
                      ?
                      (<Typography sx={{ ...webStyles.HeyTomStyles, position: "relative", margin: '15px', zIndex: 8, }}>
                        Congratulations, {this.state.userName}!
                      </Typography>)
                      :( <Typography sx={{ ...webStyles.HeyTomStyles, position: "relative", margin: '15px', zIndex: 8, }}>
                        Hey, {this.state.userName}!
                      </Typography>)
                    }
                      
                <Typography sx={webStyles.testStyleforHeyToms}>
                  {messageUp}
                </Typography>
                    </Box>
                    <Box
                      component="img"
                      alt="Logo"
                      src={wave_user.default}
                      sx={{
                        position: "absolute",
                        top: "20px",
                        right: "0px",
                        height: "105px",
                        maxWidth: "600px",
                        objectFit: "contain",
                        zIndex: 1,
                        display: "block",
                        "@media (max-width: 1045px)": {
                          height: "128px",
                        },
                        "@media (max-width: 925px)": {
                          display: "none",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        display: { xs: "none", sm: "block" },
                        width: "239px",
                        height: "160px",
                        paddingRight: "55px",
                        zIndex: 8,
                      }}
                    >
                      <img
                        src={ThumbBoy_b.default}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

          </Grid>
          <GridStyle  style={{ marginTop: "1.5rem" }}>
            <Grid item md={12} lg={12}>
              <Paper style={{borderRadius: "16px", height: "auto" }}>
             <Box  sx={{
    padding: { xs: '2px', sm: "1rem" },
    ...webStyles.leftAndrightbox,
  }}>
             <Box  sx={{  marginLeft: { xs: "0", sm: "1rem" }, marginTop:"0.6rem" , width: "100%",}}>
                <Typography sx={webStyles.relatedHarmstyle}>Substance-Related Harm Reduction</Typography>
              </Box>
                </Box>
                <div style={{ display: "flex", alignItems: "center" }}>
                <div className="vertical-text-container">
                <div className="vertical-text" style={{left:'0px'}}>
    <span style={{...webStyles.reductionStyle}}>Substance-Related Harm</span>  
  </div>
</div>

<VisualAnalytic navigation={undefined} id={""}/>
<ResponsiveContainer width="100%" height={400} style={{ marginTop: "0.5rem", marginLeft: '-39px' }}>
<LineChart
  width={500}
  height={300}
  data={updatedData?.map((d: { date:any }) => ({ ...d, date: new Date(d.date).getTime() }))} 
  margin={{ top: 10, right: 30, left: 20, bottom: 5 }}
>
  <CartesianGrid strokeDasharray="3 3" stroke="#b0b0b0" />
  <XAxis
  style={webStyles.XandYbothStyle}
  dataKey="date"
  interval={0}
  ticks={this.state.nextSixMonthsAns2?.map((date: any) =>
    typeof date === 'string' 
      ? Date.parse(date.replace(/-/g, '/')) 
      : new Date(date)?.getTime()
  )}
  type="number"
  domain={[dateMin, dateMax]}
  tickFormatter={tick => this.formatTickToShortMonth(tick)}/>
  <LabelList dataKey="harm" position="top"/>
  <YAxis ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45]} domain={[0, 45]} style={webStyles.XandYbothStyle} />
  <Tooltip content={<this.CustomTooltip />} cursor={false} />
  <Legend content={<this.CustomLegend />} />
  <Line type="monotone" dataKey="harm" stroke="#00A7D1"  dot={{ r: 4, fill: '#00A7D1' }}  activeDot={{ r: 7, fill: '#00A7D1' }} />
 
</LineChart>
</ResponsiveContainer>
</div>

              </Paper>
            </Grid>

          </GridStyle>
        </WhiteBackgroundContainer>
        <Dialog onClose={this.handleClosestartqustionary} open={this.state.qustionStart}
          PaperProps={{
            style: { borderRadius: 24 },
            background: "#0F4257"
          }}
        >
          <DialogTitle sx={{ background: "#0F4257" }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
              }}
            >

              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                <Typography
                  sx={{
                    ...webStyles.SprinStylethird,
                    fontSize: { xs: '25px', sm: '30px', md: '40px' },
                    "@media (max-width: 500px)": {
                      fontSize: '25px !important',
                    },
                    "@media (min-width:501px) and (max-width: 580px)": {
                      fontSize: '30px !important',
                    },
                  }}
                >
                  Well
                </Typography>

                <Typography
                  sx={{
                    ...webStyles.wellStylethird,
                    fontSize: {
                      xs: '25px',
                      sm: '30px',
                      md: '40px',
                    }
                  }}
                >
                  Spring
                </Typography>
              </div>
              <Typography sx={{ ...webStyles.QuestionnaireStyle, marginTop: "1.1rem" }}>
                Go to Harm Reduction Questionnaire to track your <br />
                progress every two weeks.
              </Typography>

            </Box>
            <Box
              style={{
                ...webStyles.flexBoxthird
              }}
            >
              <Box>
                <button
                  style={{ ...webStyles.harmChartTextStyle, cursor: "pointer" }}
                >
                  <Typography sx={{ ...webStyles.achievegoalstext, color: "white" }}>
                    Start Questionnaire
                  </Typography>
                </button>
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={this.handleClosestartqustionary}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Dialog>

        
  </>
      
    
      //Merge Engine End DefaultContainer
    );
    
    // Customizable Area End
  }
}

// Customizable Area Start
const PaperHead = styled(Paper)({
  "@media (max-width: 599px)": {
    width: '750px'
  },
});
const MonthBox= styled(Box)({
  "@media (max-width: 420px)": {
    marginLeft:'-30px !important'
  },
  "@media (min-width:421px and max-width: 422px)": {
     marginRight:'40px important',
    marginLeft:'0px !important'
  },
  "@media (min-width:423px and max-width: 430px)": {
    marginRight:'50px important',
    marginLeft:'0px !important'
  },
});
const GridStyle = styled(Box)({
  "@media (min-width:349px and max-width: 419px)": {
    width:'250px'
  },
  "@media (min-width: 420px) and (max-width: 466px)": {
    width:'350px'
  },
  "@media (min-width: 467px) and (max-width: 538px)": {
    width:'400px'
  },
  "@media (min-width: 539px) and (max-width: 573px)": {
    width:'450px'
  },
  "@media (min-width: 574px) and (max-width: 638px)": {
   width:'500px'
  },
  "@media (min-width: 639px) and (max-width: 660px)": {
    width:'545px'
  },
  "@media (min-width: 661px) and (max-width: 700px)": {
    width:'570px'
  }
})
const webStyles = {
  wholedivStyle:{
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    height: "100%"
  },
  wholeGridStyle:{
    height: '88px',
    backgroundColor: '#0F4257',
    borderRadius: '16px',
     margin: '10px',

  },
  well__wellStyle:{
    fontFamily: "Josefin Sans",
    lineHeight: "40px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#EBFF00",
    fontSize: "clamp(25px, 5vw, 40px)",
   },
   homeStylemenu:{
    mt: "3px",
    fontSize: "clamp(14px, 2vw, 20px)",
    lineHeight: "20px",
    color: "#FFFFFF",
    fontFamily: "Chromatica-Bold",
    fontWeight: 700,
  },
  gapM:{
    gap: "20px",
    display: "flex",
    alignItems: "center",
  },
  parentWell:{
    fontFamily: "Josefin Sans",
    color: "#ffffff",
    fontSize: "clamp(25px, 5vw, 40px)",
    fontWeight: 300,
  },

  //above my style for header

  achievegoalstext:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"white",
    textAlign:'center'
    
 },

  harmChartTextStyle:{
    padding: "0.8rem",     
    width: "348px",          
    borderRadius: "8px",     
    border: "none",       
    color:"white",    
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
},
  QuestionnaireStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color:"white"
  },
  reductionStyle:{
    color:'#00A7D1',
    fontFamily: 'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  viewStyle:{
    textUnderlineOffset: '5px',
   fontFamily:'Chromatica-Bold' ,
   textDecoration:'underline',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    },
    "&:active": {
      textDecoration: 'underline',
    },
  },
  stepperStyle:{
    marginRight:'1.5rem',
    marginLeft:'1.5rem',
    "& .Mui-active": {
      borderColor: '#B7D340 !important',
    },
    "& .Mui-completed": {
      borderColor: '#B7D340 !important',
    },
  },
  stepperZero:{
    fontSize:'24px',
    lineHeight: '32px',
    alignItems:'center',
    display:'flex',
    justifyContent:'center',
    "& .Mui-active": {
      borderColor: '#B7D340 !important',
    },
    "& .Mui-completed": {
      borderColor: '#B7D340 !important',
    },
  },
  stepperSUSStyle:{
    marginLeft:'1.5rem',
    marginRight:'1.5rem',
    "& .Mui-completed": {
      borderColor: '#6FC8DB !important',
    },
    "& .Mui-active": {
      borderColor: '#6FC8DB !important',
    },
  },
  stepperSUSZero:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:'24px',
    lineHeight: '32px',
    "& .Mui-active": {
      borderColor: '#6FC8DB !important',
    },
    "& .Mui-completed": {
      borderColor: '#6FC8DB !important',
    },
  },
  tooltipStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#00A7D1",
    borderRadius:"33px"
  },
 
  XandYbothStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"
  },
  leftAndrightbox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
   
  },

  relatedHarmstyle:{
   fontFamily:'Chromatica-Bold' ,
    fontWeight: 700,
    color:"#000000",
    fontSize: { xs: "18px", sm: "20px", md: "24px" },
    lineHeight: { xs: "26px", sm: "28px", md: "32px" }, 
    padding: { xs: "0.5rem", sm: "0.75rem", md: "0 1rem" },
  },
  flexpropertys:{
    width: "100%",
    display:"flex",
  },
  testStyleforHeyToms:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color:"#ffffff",
    margin:"0 15px 15px 15px",
    position: "relative",
    zIndex: 8,
  },
  HeyTomStyles:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color:"#EBFF00"
  },
  threebythreeStyles:{

   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
  },
  labelstylesteppers:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyles:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
YourStyles:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1",

},

SprinStylethird:{
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:"#EBFF00",
  marginTop:"1.2rem",
},
flexBoxthird:{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.8rem",
  marginTop:"1rem",
  marginBottom:"2rem"
},
wellStylethird:{ 
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:"white",
  marginTop:"1.2rem",
},
subtancesStyle:{
  fontFamily: 'Chromatica-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color:"#252525"
},
closeStyle:{
  position: 'absolute',
  right: 8,
  top: 8,
},
Congratulationsstyleinasfers:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:'#00A7D1',
},
Questionnairestyleinasfers:{
fontFamily: 'Josefin Sans',
fontSize: '40px',
fontWeight: 700,
lineHeight: '40px',
letterSpacing: '-0.04em',
color:'#EBFF00',
},
QustionStyleText:{
fontFamily: 'Chromatica-Regular',
fontSize: '20px',
fontWeight: 500,
lineHeight: '24px',
color:'white',display:"flex",justifyContent:'center',alignItems:"center"
},
Nextstylemove:{
  fontFamily: 'Chromatica-bold', 
  fontSize: '18px', 
  fontWeight: 700, 
  lineHeight: '24px',
  cursor:"pointer"
 }
};



// Customizable Area End