import React from "react";
// Customizable Area Start
import { Box,Grid , Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  FormControl,
  FormLabel} from "@mui/material";
  import "../../../blocks/assessmenttest/assets/assets.css"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from "@mui/material/styles"; 
import { arrow_arrow, five_five, menu_menu_assessment, photo_photo, sun_sunset } from "./assets";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const StyledButtonqustiona = styled(Button)({
  background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
color: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  fontWeight: "bold",
  textTransform: "none",
  width:'348px',
  cursor:"pointer",
  "&:hover": {
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)"
  }
});
const StyledButton = styled(Button)({
  background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
color: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  fontWeight: "bold",
  textTransform: "none",
  width:'348px',
  "&:hover": {
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)"
  }
});
const StyledButtonback = styled(Button)({
  position: "absolute", paddingTop: '1.3rem', left: 30 ,cursor:"pointer",top:"1.5rem"
 
});
// Customizable Area End
import AssessmentController, {
  Props,
  
} from "./AssessmentController.web"




export default class Assessment extends AssessmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  
  // Customizable Area End

  render() {
    // Customizable Area Start
  
   
    return (
      //Merge Engine DefaultContainer
  <>
   
 
  <Grid
  container
       
        style={{
        
        
          backgroundColor: "#F0F0F0",
       
        }}
        className="managenabar"
      >
        <Grid
          item md={12} xs={12} sm={12}
          
          style={{
            height: '88px',
          
            borderRadius: '16px',
            width: '98.5%',
            backgroundColor: '#0F4257',
            margin: '10px',
            
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
              width: "100%",
              display: "flex",
            
             
            
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: "20px",
                "@media (max-width: 450px)": {
                  gap: "10px",
                }
              }}
            >
              <Box
                sx={{
                  color: "#ffffff",
                  fontWeight: 300,
                  fontFamily: "Josefin Sans",
                  fontSize: "clamp(25px, 5vw, 40px) !important",
               
                }}
                 className="fontsizepresent"
              >
                <Box
                  component="span"
                  sx={{
                    marginLeft: {
                      xs: '17px !important',
                      md: '48px !important',
                    },
                    fontFamily: "Josefin Sans",
                    fontWeight: 700,
                  
                    fontSize: "clamp(25px, 5vw, 40px) !important",
                    marginBottom: "4px",
                    color: "#EBFF00",
                    lineHeight: "40px",
                  }}
                     className="well__wellStylesgoals"
                >
                  Well
                </Box>
                Spring
              </Box>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Chromatica-Bold",
                  fontWeight: 700,
                  mt: "3px",
                  fontSize: "clamp(14px, 2vw, 20px)",
                  lineHeight: "20px",               
                  "@media (max-width: 590px)": {
                    width: "40%",
                    wordBreak: "break-word",
                  }
                }}
                
              >
                Hey, {this.state.userName}
              </Typography>
            </Box>
            <Box
            
              onClick={() => { this.hamburgerMenuass() }}
              component="img"
              src={menu_menu_assessment.default}
              alt="menuIcon"
              sx={{
                marginRight: "50px",
                cursor: "pointer",
                "@media (max-width: 530px)": {
                  marginRight: "20px !important"},
                "@media (min-width: 531px) and (max-width:1200px)": {
                  marginRight: "30px !important"},
              }}
            />
          </Box>
        </Grid>

        <Grid
          item md={12} xs={12} sm={12}
          style={{
            width: '98.5%',
            margin: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '15px',
           
            backgroundColor: 'white',
            position:"relative",zIndex:1 ,  
          
         
         

          }}
          className="widthStyle"

        >
  
     <StyledButtonback
     className="backstylemobile"
     sx={{cursor:"pointer", margin: "0 !important"}}
              onClick={this.handleBack}
            >
              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', gap: "0.7rem",cursor:"pointer"}}>
                <span>
                  <img src={arrow_arrow.default} style={{ color: 'black', width: '12px', height: '8px' }} />
                </span>
                <span style={{
                  fontFamily: 'Chromatica-bold',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  color: "#252525",
                  cursor:"pointer",
                  textTransform: "none"
                }}>
                  Back
                </span>

              </div>
            </StyledButtonback>

          <Box sx={{ position: "relative",  height:'auto',
                   top: "4rem", marginLeft: '2rem',zIndex:1 }} className="marginmange">
            <Box
              component="img"
              src={five_five.default}
              alt="Logo Top Left"
              sx={{ width: '120px', height: '180px' }}
              className="fivefive"
            />
            <Typography
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
              
                lineHeight:"32px",
                fontSize: '32px',
                color: '#00A7D1',
                marginTop: '109px',
                fontFamily: 'Josefin Sans',
                fontWeight: 600,
              }}
              className="bottomtext"
            >
            Harm Reduction Questionnaire
            </Typography>
            <Typography sx={{
              fontFamily: 'Chromatica-Regular',
              fontSize: '16px !important',
              color: '#252525',
              fontWeight:400,
              marginBottom: '20px'
            }}
            className="aboutduiring"
            >
            During the past 30 days, about how often has this happened to you?
            </Typography>
          
           
          </Box>
   
          <Box sx={{ padding:"3rem 2rem",marginTop:"3rem"}}>
        <FormControl component="fieldset">
          <FormLabel component="legend" sx={{position:'relative',zIndex:2}}>
            <Typography variant="h6" gutterBottom sx={
                    {
                      fontFamily: 'Chromatica-Regular',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: "#252525"

              }
            } className="currentqustionstyle">
              {this.state.questions[this.state.currentQuestion]?.question}
            </Typography>
          </FormLabel>
          <RadioGroup value={this.state.selectedValue} onChange={this.handleRadioChange} sx={{position:'relative',zIndex:2}}>
            {this.state.questions[this.state.currentQuestion]?.options.map((option:any, index:any) => (
            <div
            style={{
              border: this.state.selectedValue === option ? "1px solid #00A7D1" : "1px solid #E3E3E3",
              borderRadius: "32px",
              margin: "10px 0",
              padding: "8px 12px",
              width: option === "Never" ? "161px" : "224px",
              display: "block",
            }}
          >
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio sx={{
                '&.Mui-checked': {
                  color: '#00A7D1 !important',
                },
              }} />}
              label={option} 
              sx={{ '& .MuiFormControlLabel-label': { 
                fontFamily: 'Chromatica-Regular',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                color:"#252525" } }}
                className="fontinthebox"
            />
          </div>
          
            ))}
          </RadioGroup>
        </FormControl>

              <Box mt={3} textAlign="left" sx={{ position: 'relative', zIndex: 2 ,cursor:"pointer"}}>
              <StyledButton
              className="buttonmediamobile"
          disabled={!this.state.selectedValue}
          onClick={
            this.state.currentQuestion === this.state.questions.length - 1 
              ? this.handleComplete
              : this.handleNextass 
          }
        >
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', gap: "0.3rem" }}>
            <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>
              {this.state.currentQuestion === this.state.questions.length - 1 ? "Finished" : this.state.buttonText}
            </span>
            <span>
              <KeyboardArrowRightIcon style={{ color: 'white', width: '24px', height: '24px' }} />
            </span>
          </div>
        </StyledButton>
               
        </Box>
              
      <Box
              sx={{
                position: 'absolute',
                top: "25%",
                bottom: '-2%',
                left: '0',
                width: '100%',
                background:"white",
                "@media (max-width: 1390px)": {
                  top: "28%"
                },
                "@media (max-width: 1300px)": {
                  top: "34%"
                },
                "@media (max-width: 1200px)": {
                  top: "40%"
                },
                "@media (max-width: 1140px)": {
                  top: "43%"
                },
                "@media (max-width: 1100px)": {
                  top: "46%"
                },
                "@media (max-width: 1086px)": {
                  top: "60%"
                },
                "@media (max-width: 1035px)": {
                  top: "65%"
                },
                "@media (max-width: 1025px)": {
                  top: "87%"
                },
                "@media (max-width: 990px)": {
                  top: "67%"
                },
                "@media (max-width: 960px)": {
                  top: "70%"
                },
                "@media (max-width: 920px)": {
                  top: "95%",
                },
                "@media (max-width: 860px)": {
                  top: "90%",
                },
                "@media (max-width: 820px)": {
                  top: "93%"
                },
              }}
            className="imagehide"
            >
              <img
                src={sun_sunset.default}
                alt="Sunset"
                style={{
                  backgroundColor:'white',
                  width: "100%",
                  height: 'auto',
                  position: 'relative',
                borderRadius:"16px"
                }}
              />
            </Box>

         </Box>
        </Grid>
       
      </Grid>

 
          <Dialog  open={this.state.openQustinary}
          PaperProps={{
            style: {
              borderRadius: 24, width: 622,
              overflowX: "hidden", background: "#0F4257", padding: '2rem'


            },
          }}
          className="paddinremove"
        >
          <DialogTitle>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // To center text as well
                  width: '100%',

                }}
              >


                <Typography sx={webStyles.Questionnairestyleinasfers} className="font">
                  Questionnaire Completed
                </Typography>

              </Box>
              <Box sx={{ marginTop: "1rem" }}>
                <Typography style={{ ...webStyles.QustionStyleText }}>
          {this.state.successMsg}
                </Typography>
         
               
                                    
              </Box>
            </Box>
            <div style={{
              display: "flex", justifyContent: 'center', alignItems: "center",
              marginTop: "2rem"
            }}>
              <StyledButtonqustiona
                disabled={!this.state.selectedValue} onClick={() => this.goToDashboard()}>

                <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>
                  Go to Dashboard
                </span>
              </StyledButtonqustiona>
            </div>


          </DialogTitle>


        </Dialog>


     

          
          

  </>
      
    
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  subtancesStyle:{
    fontFamily: 'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color:"#252525"
  },
  closeStyle:{
    position: 'absolute',
    right: 8,
    top: 8,
},
  Congratulationsstyleinasfers:{
    fontFamily: 'Josefin Sans',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '32px',
    color:'#00A7D1',
},
Questionnairestyleinasfers:{
  fontFamily: 'Josefin Sans',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:'#EBFF00',
},
QustionStyleText:{
  fontFamily: 'Chromatica-Regular',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '24px',
  color:'white',display:"flex",justifyContent:'center',alignItems:"center"
},
  Nextstylemove:{
    fontFamily: 'Chromatica-bold', 
    fontSize: '18px', 
    fontWeight: 700, 
    lineHeight: '24px',
    cursor:"pointer"
   }
};

// Customizable Area End